import { ITableRequestParams } from '@Types/Table';
import { apiSlice } from './apiSlice';
import { IGenericListModel } from '@Types/GenericListModel';
import { INotificationHistory } from '@Types/NotificationHistory';

const paymentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    notificationsHistory: builder.query<
      IGenericListModel<INotificationHistory>,
      ITableRequestParams
    >({
      query: ({ sortFields, search, pageSize, currentPage, getAllPages }) => ({
        url: '/api/NotificationManagement/GetAll',
        method: 'POST',
        body: {
          sortFields,
          search,
          pageSize,
          currentPage,
          getAllPages,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: ['notifications-history'] as any,
    }),
    notificationsHistoryBySelectedId: builder.mutation<
      INotificationHistory[],
      string[]
    >({
      query: (data) => ({
        url: '/api/NotificationManagement/GetNotificationsById',
        method: 'POST',
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useNotificationsHistoryQuery,
  useLazyNotificationsHistoryQuery,
  useNotificationsHistoryBySelectedIdMutation,
} = paymentsApi;
