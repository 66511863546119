import { GridColDef } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { ExtendedGridColDef } from '@Types/ExtendedGridColDef';
import { IFAQ } from '@Types/FAQ';

export const faqColumns: (GridColDef<IFAQ> & ExtendedGridColDef)[] = [
  {
    field: 'question',
    headerName: 'Question',
    hideable: false,
    flex: 0.9,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span className='table-cell-truncate'>{params.value}</span>
        </Tooltip>
      );
    },
  },
];
