import { IUser } from '@Store/slices/auth';
import { Box, Button, styled, Typography } from '@mui/material';

const DetailsContainer = styled(Box)`
  margin-top: 30px;
  display: flex;
`;

const Details = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const DetailsTitle = styled(Typography)`
  color: #181818;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;

const DetailsValue = styled(Typography)`
  color: #181818;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const StyledTypography = styled(Typography)`
  color: #807f83;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  padding: '18px 20px',
  borderRadius: 20,
  color: '#FFF',
  marginTop: '60px',

  '&:hover': { backgroundColor: theme.palette.error.main },
}));

interface IProps {
  user: IUser;
  onDelete: () => void;
}

export function DeleteUserContainer({ user, onDelete }: IProps) {
  return (
    <Box>
      <StyledTypography>
        You are going to delete user permanently, are you sure about it?
      </StyledTypography>
      <DetailsContainer>
        <Details>
          <DetailsTitle>User ID</DetailsTitle>
          <DetailsValue>{user.id}</DetailsValue>
        </Details>
        <Details sx={{ ml: '60px' }}>
          <DetailsTitle>Phone Number</DetailsTitle>
          <DetailsValue>{user.phoneNumber}</DetailsValue>
        </Details>
        <Details sx={{ ml: '60px' }}>
          <DetailsTitle>Email</DetailsTitle>
          <DetailsValue>{user.email}</DetailsValue>
        </Details>
      </DetailsContainer>
      <DeleteButton fullWidth onClick={onDelete}>
        Delete
      </DeleteButton>
    </Box>
  );
}
