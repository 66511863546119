import { ITableRequestParams, OrderType } from '@Types/Table';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

function useTableRequestParams(): ITableRequestParams {
  const [searchParams] = useSearchParams();

  const params = useMemo(() => {
    const sortFields =
      searchParams.get('Sorts.OrderField') &&
      searchParams.get('Sorts.SortOrder')
        ? [
            {
              name: searchParams.get('Sorts.OrderField')!,
              order: searchParams.get('Sorts.SortOrder')! as OrderType,
            },
          ]
        : [];

    const search = searchParams.get('search') || '';
    const pageNumber = parseInt(searchParams.get('Page.PageNumber') || '1');
    const pageSize = parseInt(searchParams.get('Page.PageSize') || '10');

    return {
      sortFields,
      search,
      currentPage: pageNumber,
      pageSize,
    } as ITableRequestParams;
  }, [searchParams]);

  return params;
}

export default useTableRequestParams;
