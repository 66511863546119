import { IUser } from '@Store/slices/auth';
import { apiSlice } from './apiSlice';

interface IUpdatePasswordRequest {
  username: string;
  password: string;
  confirmPassword: string;
}
interface ILoginResponse {
  accessToken: string;
  refreshToken: string;
  type: string;
}

interface ILoginRequest {
  username: string;
  password: string;
  recaptchaToken: string;
}

interface IVerifyRequest {
  recipient: string;
  code: string;
}

const loginApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: (credentials) => ({
        url: '/api/Authorization/SignIn',
        method: 'POST',
        body: credentials,
        validateStatus: (args) => {
          if (args.status >= 400) return false;

          return args.status !== 202;
        },
      }),
    }),
    verify: builder.mutation<ILoginResponse, IVerifyRequest>({
      query: (credentials) => ({
        url: '/api/OTP/Verify',
        method: 'POST',
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation<any, { username: string }>({
      query: (data) => ({
        url: '/api/Account/ForgotPassword',
        method: 'POST',
        body: data,
      }),
    }),
    updatePassword: builder.mutation<void, IUpdatePasswordRequest>({
      query: (data) => ({
        url: '/api/Account/UpdatePassword',
        method: 'POST',
        body: data,
      }),
    }),
    getProfile: builder.query<IUser, string>({
      query: (id) => ({
        url: `/api/Users/Get/${id}`,
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useVerifyMutation,
  useLazyGetProfileQuery,
  useForgotPasswordMutation,
  useUpdatePasswordMutation,
} = loginApi;
