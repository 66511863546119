import { GridColDef } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { ExtendedGridColDef } from '@Types/ExtendedGridColDef';
import { ICustomer } from '@Types/customerType';
import CopyColumn from '@Components/table/CopyColumn';

export const customerReportColumns: (GridColDef<ICustomer> &
  ExtendedGridColDef)[] = [
  {
    field: 'id',
    headerName: 'ID',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      return <CopyColumn value={params.value} />;
    },
    valueGetter: (params) => `${params.row.id}`,
  },
  {
    field: 'name',
    headerName: 'Name',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.name,
  },
  {
    field: 'vatNumber',
    headerName: 'VAT Number',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.vatNumber,
  },
  {
    field: 'reminderFee',
    headerName: 'Reminder Fee',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return <Tooltip title={value}>{value}</Tooltip>;
    },
    valueGetter: (params) => params.row.reminderFee,
  },
  {
    field: 'merchantId',
    headerName: 'Merchant ID',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.merchantId,
  },
];
