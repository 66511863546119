import Table from '@Components/table';
import { useDeleteUserMutation, useUsersQuery } from '@Store/api/usersSlice';
import { userColumns } from './columns';
import { Appbar } from '@Components/app-bar/AppBar.container';
import { Box, Button, styled } from '@mui/material';
import AddNewUserIcon from '../../assets/icons/add-new-user-icon.svg';
import useTableRequestParams from 'hooks/useTableRequestParams';
import { Modal } from '@Components/modal';
import useModal from 'hooks/useModal';
import { CreateOrEditUserForm } from './create-or-edit-user';
import useActionColumn from '@Components/table/useActionColumn';
import { IUser } from '@Store/slices/auth';
import { useCallback, useEffect } from 'react';
import { DeleteUserContainer } from './delete-user';
import { handleFetchError } from '@Utils/handleError';

const Container = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AddNewUserButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: '18px 20px',
  borderRadius: 30,
  color: '#FFF',
  img: {
    marginRight: 10,
  },
  textTransform: 'capitalize',
  '&:hover': { backgroundColor: theme.palette.secondary.main },
}));

function Users() {
  const {
    isOpen: isCreateOrEditModalOpen,
    item: userToEdit,
    openModal: openCreateOrEditModal,
    closeModal: closeCreateOrEditModal,
  } = useModal<IUser>();

  const {
    isOpen: isDeleteModalOpen,
    item: userToDelete,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal<IUser>();

  const tableParams = useTableRequestParams();
  const { data: users, error: tableDataError } = useUsersQuery(tableParams);

  const [deleteUser] = useDeleteUserMutation();

  const handleOpenEditUser = useCallback(
    (user: IUser) => {
      openCreateOrEditModal(user);
    },
    [openCreateOrEditModal]
  );

  const handleOpenDeleteUser = useCallback(
    (user: IUser) => {
      openDeleteModal(user);
    },
    [openDeleteModal]
  );

  const handleDelete = async () => {
    await deleteUser(userToDelete.id).unwrap();
    closeDeleteModal();
  };

  const actionColumn = useActionColumn({
    handleEdit: handleOpenEditUser,
    handleDelete: handleOpenDeleteUser,
  });

  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <Container component='div'>
      <Appbar searchable title={'Users'}>
        <AddNewUserButton onClick={() => openCreateOrEditModal()}>
          <img alt='Add New User' src={AddNewUserIcon} />
          Add New User
        </AddNewUserButton>
      </Appbar>
      <Table
        paginated
        columns={[...userColumns, actionColumn]}
        rows={users?.items || []}
        totalCount={users?.count || 0}
      />

      {isDeleteModalOpen ? (
        <Modal
          labelErrored
          handleClose={closeDeleteModal}
          label={'Delete user'}
          open={isDeleteModalOpen}
        >
          <DeleteUserContainer user={userToDelete} onDelete={handleDelete} />
        </Modal>
      ) : null}

      {isCreateOrEditModalOpen ? (
        <Modal
          handleClose={closeCreateOrEditModal}
          label={userToEdit ? 'Edit user' : 'Add user'}
          open={isCreateOrEditModalOpen}
        >
          <CreateOrEditUserForm
            handleClose={() => closeCreateOrEditModal()}
            user={userToEdit}
          />
        </Modal>
      ) : null}
    </Container>
  );
}

export default Users;
