import { TokenResponse } from '@Types/customerType';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { logout } from '@Store/slices/auth';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_API_URL,
  prepareHeaders: (headers) => {
    headers.set(
      'Authorization',
      `Bearer ${localStorage.getItem('accessToken')}`
    );

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshRequest: FetchArgs = {
      url: `${process.env.REACT_APP_BASE_API_URL}/api/Authorization/RefreshToken`,
      method: 'POST',
      body: {
        refreshToken: localStorage.getItem('refreshToken'),
      },
    };

    const refreshResult = await baseQuery(refreshRequest, api, extraOptions);

    if (refreshResult.data) {
      const refreshToType = refreshResult.data as TokenResponse;

      localStorage.setItem('accessToken', refreshToType.accessToken);
      localStorage.setItem('refreshToken', refreshToType.refreshToken);

      return await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
