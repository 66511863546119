import React from 'react';

import { Modal as MUIModal, Box, styled, Typography } from '@mui/material';

const CloseIcon = ({
  styles,
  onClick,
}: {
  styles: React.CSSProperties;
  onClick: () => void;
}) => (
  <svg
    fill='none'
    height='42'
    style={styles}
    viewBox='0 0 42 42'
    width='42'
    xmlns='http://www.w3.org/2000/svg'
    onClick={onClick}
  >
    <rect
      fill='white'
      height='40'
      rx='10'
      stroke='#D0CFCD'
      width='40'
      x='1'
      y='1'
    />
    <path
      d='M26.7472 25.535C26.9081 25.696 26.9986 25.9143 26.9986 26.1419C26.9986 26.3695 26.9081 26.5879 26.7472 26.7488C26.5862 26.9098 26.3679 27.0002 26.1403 27.0002C25.9126 27.0002 25.6943 26.9098 25.5333 26.7488L21 22.2141L16.4652 26.7474C16.3043 26.9084 16.086 26.9988 15.8583 26.9988C15.6307 26.9988 15.4124 26.9084 15.2514 26.7474C15.0904 26.5864 15 26.3681 15 26.1405C15 25.9128 15.0904 25.6945 15.2514 25.5336L19.7862 21.0002L15.2528 16.4655C15.0919 16.3045 15.0014 16.0862 15.0014 15.8585C15.0014 15.6309 15.0919 15.4126 15.2528 15.2516C15.4138 15.0907 15.6321 15.0002 15.8597 15.0002C16.0874 15.0002 16.3057 15.0907 16.4667 15.2516L21 19.7864L25.5348 15.2509C25.6957 15.0899 25.914 14.9995 26.1417 14.9995C26.3693 14.9995 26.5876 15.0899 26.7486 15.2509C26.9096 15.4119 27 15.6302 27 15.8578C27 16.0855 26.9096 16.3038 26.7486 16.4647L22.2138 21.0002L26.7472 25.535Z'
      fill='#807F83'
    />
  </svg>
);

interface ITypographyProps {
  labelErrored?: boolean;
}

const StyledTypography = styled(Typography)<ITypographyProps>`
  font-size: 16px;
  font-weight: 700;
  color: ${({ labelErrored, theme }) =>
    labelErrored ? theme.palette.error.main : undefined};
  padding-bottom: 30px;
`;

interface IProps {
  open: boolean;
  label: string;
  children: React.ReactNode;
  labelErrored?: boolean;
  sx?: React.CSSProperties;
  transparent?: boolean;
  width?: number | string;
  handleClose: () => void;
}

export const Modal = ({
  open,
  labelErrored,
  label,
  children,
  sx,
  transparent,
  width,
  handleClose,
}: IProps) => {
  return (
    <MUIModal
      BackdropProps={{
        style: { backgroundColor: transparent ? 'transparent' : undefined },
      }}
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={
          sx ?? {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: width ?? 600,
            bgcolor: 'background.paper',
            borderRadius: '30px',
            boxShadow: 24,
            p: 4,
          }
        }
      >
        <StyledTypography labelErrored={labelErrored} variant='body2'>
          {label}
          <CloseIcon
            styles={{
              position: 'absolute',
              cursor: 'pointer',
              right: 30,
              top: 20,
            }}
            onClick={handleClose}
          />
        </StyledTypography>
        {children}
      </Box>
    </MUIModal>
  );
};
