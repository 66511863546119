import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const EMAIL_REGX =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export interface IForgotPassword {
  email: string;
}

const schema = yup.object<IForgotPassword>().shape({
  email: yup
    .string()
    .matches(EMAIL_REGX, 'Invalid email')
    .required('Invalid Email'),
});

export default yupResolver(schema);
