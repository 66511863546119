/* eslint-disable react/hook-use-state */
import { useMemo } from 'react';
import { useState, useCallback } from 'react';

const useModal = <T>(initialState = false, initState = null): IReturn<T> => {
  const [isOpen, setIsOpen] = useState(initialState);
  const [showSuccess, setSuccess] = useState(false);
  const [showProgress, setProgress] = useState(false);
  const [showError, setShowError] = useState(false);
  const [item, setSelectedItem] = useState<any>(initState);

  const closeModal = useCallback(() => {
    setSelectedItem(null);
    setIsOpen(false);
  }, [setIsOpen]);

  const openModal = useCallback(
    (setItem = null) => {
      setSelectedItem(setItem);
      setSuccess(false);
      setProgress(false);
      setShowError(false);
      setIsOpen(true);
    },
    [setSuccess, setProgress, setShowError, setIsOpen]
  );

  const state = useMemo(() => {
    return {
      isOpen,
      showSuccess,
      showProgress,
      showError,
      setSuccess,
      setProgress,
      setShowError,
      closeModal,
      openModal,
      item,
    };
  }, [
    isOpen,
    showSuccess,
    showProgress,
    showError,
    setSuccess,
    setProgress,
    setShowError,
    closeModal,
    openModal,
    item,
  ]);

  return state;
};

interface IReturn<T> {
  isOpen: boolean;
  showSuccess: boolean;
  showProgress: boolean;
  showError: boolean;
  item: T;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setProgress: React.Dispatch<React.SetStateAction<boolean>>;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal: () => void;
  openModal: (setItem?: any) => void;
}

export default useModal;
