import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ICreateOrEditFAQ } from '@Types/FAQ';

const schema = yup.object<ICreateOrEditFAQ>().shape({
  question: yup.string().required('Question is required'),
  answer: yup.string().required('Answer is required'),
});

export default yupResolver(schema);
