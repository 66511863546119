import { IPolygonFormData } from 'pages/space-tarrif-map-new-customer/CustomerForm.validation';
import { apiSlice } from './apiSlice';
import {
  ICustomer,
  ICustomerSelectionReport,
  IGetCustomersData,
} from '@Types/customerType';
import { IDataToSend } from 'pages/space-tarrif-map-new-customer/SpaceTarrifMapNewCustomer';
import { ITableRequestParams } from '@Types/Table';
import { IGenericListModel } from '@Types/GenericListModel';

const customersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomer: builder.query<IPolygonFormData, any>({
      query: (id) => ({
        url: `/api/Customers/Get/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => {
        const data = { ...response } as IPolygonFormData;

        data.selections.forEach((each) => {
          each.coordinates = JSON.parse(each.areaMap!);
        });

        return data;
      },
      providesTags: ['customers'] as any,
    }),
    createCustomer: builder.mutation<IPolygonFormData, IDataToSend>({
      query: (data) => ({
        url: '/api/Customers/Add',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['customers'] as any,
    }),
    updateCustomer: builder.mutation<void, IDataToSend>({
      query: (data) => ({
        url: '/api/Customers/Update',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['customers'] as any,
    }),
    deleteCustomer: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/Customers/Remove/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['customers'] as any,
    }),
    getCustomers: builder.query<IGetCustomersData, ITableRequestParams>({
      query: ({ sortFields, search, pageSize, currentPage, getAllPages }) => ({
        url: '/api/Customers/GetAll',
        method: 'POST',
        body: { sortFields, search, pageSize, currentPage, getAllPages },
      }),
      providesTags: ['customers'] as any,
      keepUnusedDataFor: 0,
    }),
    customersReportBySelectedId: builder.mutation<ICustomer[], string[]>({
      query: (data) => ({
        url: '/api/Customers/GetCustomersById',
        method: 'POST',
        body: data,
      }),
    }),
    getCustomersSelections: builder.query<
      IGenericListModel<ICustomerSelectionReport>,
      ITableRequestParams
    >({
      query: ({ sortFields, search, pageSize, currentPage, getAllPages }) => ({
        url: '/api/Customers/GetAllSelections',
        method: 'POST',
        body: { sortFields, search, pageSize, currentPage, getAllPages },
      }),
      providesTags: ['customer-selections'] as any,
      keepUnusedDataFor: 0,
    }),
    customersSelectionsById: builder.mutation<
      ICustomerSelectionReport[],
      string[]
    >({
      query: (data) => ({
        url: '/api/Customers/GetSelectionsById',
        method: 'POST',
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateCustomerMutation,
  useGetCustomerQuery,
  useUpdateCustomerMutation,
  useGetCustomersQuery,
  useLazyGetCustomersQuery,
  useDeleteCustomerMutation,
  useCustomersReportBySelectedIdMutation,
  useGetCustomersSelectionsQuery,
  useLazyGetCustomersSelectionsQuery,
  useCustomersSelectionsByIdMutation,
} = customersApi;
