import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';

import { styled } from '@mui/material';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import { navBarItems } from './constants';

const MyNavLink = React.forwardRef<any, any>((props, ref) => (
  <NavLink
    className={({ isActive }) =>
      `${props.className} ${isActive ? props.activeClassName : ''}`
    }
    ref={ref}
    to={props.to}
  >
    {props.children}
  </NavLink>
));

const StyledList = styled<any>(List)(({ theme }) => ({
  marginTop: '28px',
  color: '#fff',

  a: {
    textDecoration: 'none',
    color: '#fff',

    '&:not(:first-child)': {
      marginTop: '10px',
    },
  },

  '.subitem-active': {
    color: theme.palette.secondary.main,
  },

  '.active': {
    backgroundColor: 'rgba(246, 245, 245, 0.1)',
    borderRadius: 10,
    fontWeight: 700,
  },

  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'rgba(246, 245, 245, 0.1)',
    borderRadius: 10,
  },
}));

type CollapsedItems = 'Management' | 'Reporting' | 'Policies' | '';

interface IProps {
  isNavbarOpen: boolean;
}

function MenuItems({ isNavbarOpen }: IProps) {
  const [collapsedItem, setCollapsedItem] = useState<CollapsedItems>('');

  const handleCollapse = (item: CollapsedItems) => () => {
    if (collapsedItem === item) {
      return;
    } else {
      setCollapsedItem(item);
    }
  };

  return (
    <StyledList component='nav'>
      {navBarItems.map((item) => {
        return (
          <div key={item.title}>
            <ListItemButton
              component={NavLink}
              key={item.title}
              sx={{ marginTop: 1, height: 40 }}
              to={item.to}
              onClick={handleCollapse(item.title as CollapsedItems)}
            >
              <ListItemIcon sx={{ minWidth: isNavbarOpen ? 40 : 30 }}>
                <img alt={item.title} src={item.icon} />
              </ListItemIcon>
              {isNavbarOpen ? <ListItemText primary={item.title} /> : null}
            </ListItemButton>
            {item.subItems.length > 0 && isNavbarOpen ? (
              <Collapse
                unmountOnExit
                in={collapsedItem === item.title}
                timeout='auto'
              >
                <List
                  component='div'
                  sx={{
                    marginLeft: 7,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {item.subItems.map((subItem) => {
                    return (
                      <MyNavLink
                        activeClassName={'subitem-active'}
                        key={subItem.title}
                        to={subItem.to}
                      >
                        {subItem.title}
                      </MyNavLink>
                    );
                  })}
                </List>
              </Collapse>
            ) : null}
          </div>
        );
      })}
    </StyledList>
  );
}

export default MenuItems;
