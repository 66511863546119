import { IFAQ } from '@Types/FAQ';
import { apiSlice } from './apiSlice';
import { ITableRequestParams } from '@Types/Table';

export enum PolicyContentType {
  Unknown = 0,
  TermsAndConditions = 1,
  PrivacyPolicy = 2,
  ParkingGuide = 3,
}

interface IContent {
  content: string;
  contentType: PolicyContentType;
  id: string;
}

interface IGetFAQResponse {
  items: any[];
}

const policiesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFAQ: build.query<IGetFAQResponse, Partial<ITableRequestParams>>({
      query: ({ search }) => ({
        url: '/api/Policies/GetFAQ',
        params: {
          search: search,
        },
      }),
      providesTags: ['FAQ'] as any,
      keepUnusedDataFor: 0,
    }),
    createFAQ: build.mutation<IFAQ, Omit<IFAQ, 'id'>>({
      query: (data) => ({
        url: '/api/Policies/AddFAQ',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['FAQ'] as any,
    }),
    updateFAQ: build.mutation<void, IFAQ>({
      query: (data) => ({
        url: '/api/Policies/UpdateFAQ',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['FAQ'] as any,
    }),
    getContent: build.query<IContent, PolicyContentType>({
      query: (contentType) => ({
        url: '/api/Policies/GetPolicy',
        params: {
          contentType,
        },
      }),
      providesTags: ['policy'] as any,
      keepUnusedDataFor: 0,
    }),
    addContent: build.mutation<any, any>({
      query: (data) => ({
        url: '/api/Policies/AddPolicy',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['policy'] as any,
    }),
    updateContent: build.mutation<void, IContent>({
      query: (data) => ({
        url: '/api/Policies/UpdatePolicy',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['policy'] as any,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetContentQuery,
  useGetFAQQuery,
  useCreateFAQMutation,
  useUpdateFAQMutation,
  useUpdateContentMutation,
  useAddContentMutation,
} = policiesApi;
