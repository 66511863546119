import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export interface IPasswordRecovery {
  password: string;
  confirmPassword: string;
}

const schema = yup.object<IPasswordRecovery>().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .max(12, 'Password cannot exceed more than 12 characters'),
  confirmPassword: yup
    .string()
    .required('Confirm Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .max(12, 'Password cannot exceed more than 12 characters')
    .oneOf([yup.ref('password')], 'Passwords do not match'),
});

export default yupResolver(schema);
