import { Outlet, useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import Background from '../../../assets/images/background.jpg';
import Logo from '../../../assets/images/logo.png';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '@Store/slices/auth/selectors';
import { useEffect } from 'react';
import { DASHBOARD_PAGE } from '@Routes/constants';

const Layout = styled(Box)`
  background-image: url(${Background});
  background-position: center;
  background-size: cover;
  background-repeat: 'no-repeat';
  width: 100vw;
  height: 100vh;
`;

const LayoutColored = styled(Box)`
  background-color: rgba(0, 83, 94, 0.9);
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
`;

const LeftSide = styled(Box)`
  width: 50%;

  img {
    margin-top: 70px;
    margin-bottom: 80px;
    margin-left: 180px;
  }
`;

const RightSide = styled(Box)`
  width: 50%;
  display: flex;
`;

const AuthBox = styled(Box)`
  width: 600px;
  height: 540px;
  background-color: #fff;
  border-radius: 30px;
  padding: 60px;
  margin-left: 180px;
`;

const TextBox = styled(Box)`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;

  p {
    margin-top: 30px;
  }
`;

const AuthLayout = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(DASHBOARD_PAGE);
    }
  }, [isLoggedIn, navigate]);

  return (
    <Layout>
      <LayoutColored>
        <LeftSide>
          <img alt='Weltome to IPS Group' src={Logo} />
          <AuthBox>
            <Outlet />
          </AuthBox>
        </LeftSide>
        <RightSide>
          <TextBox>
            <Typography variant='h3'>Welcome To IPS Group</Typography>
            <Typography variant='body1'>
              Boost your city's productivity by managing the entire parking
              lifecycle with one powerful, fully integrated Parking Management
              Solution.
            </Typography>
          </TextBox>
        </RightSide>
      </LayoutColored>
    </Layout>
  );
};

export default AuthLayout;
