import { RootState } from '@Store/types';
import { useSelector } from 'react-redux';

const skipLoadingFor = new Set(['getProfile']);

const useIsSomeQueryPending = () => {
  const isSomeQueryPending = useSelector((state: RootState) => {
    const isPending =
      Object.values(state.api.queries).some(
        (query) =>
          query?.status === 'pending' &&
          !skipLoadingFor.has(query?.endpointName)
      ) ||
      Object.values(state.api.mutations).some(
        (query) =>
          query?.status === 'pending' &&
          !skipLoadingFor.has(query?.endpointName)
      );

    return isPending;
  });

  return isSomeQueryPending;
};

export default useIsSomeQueryPending;
