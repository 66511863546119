import { Appbar } from '@Components/app-bar';
import { Button, Box, styled } from '@mui/material';
import useModal from 'hooks/useModal';
import { Modal } from '@Components/modal';
import { EditContentModal } from '@Components/edit-content-modal/EditContentModal.Container';
import {
  PolicyContentType,
  useAddContentMutation,
  useGetContentQuery,
  useUpdateContentMutation,
} from '@Store/api/policiesSlice';
import { useMemo } from 'react';
import parse from 'html-react-parser';

const Container = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const AddNewCustomerButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: '18px 20px',
  borderRadius: 30,
  color: '#FFF',
  img: {
    marginRight: 10,
  },
  textTransform: 'capitalize',
  '&:hover': { backgroundColor: theme.palette.secondary.main },
}));

export function ParkingGuide() {
  const { data, error } = useGetContentQuery(PolicyContentType.ParkingGuide);
  const [updateContent] = useUpdateContentMutation();
  const [addContent] = useAddContentMutation();

  const {
    isOpen: isEditContentOpen,
    item: editContentItem,
    openModal: openEditContentModal,
    closeModal: closeEditContentModal,
  } = useModal<string>();

  const isAddContentMode = useMemo(() => {
    return !!error && (error as any).status === 404;
  }, [error]);

  const handleSaveContent = async (newContent: string) => {
    if (isAddContentMode) {
      const _response = await addContent({
        content: newContent,
        contentType: PolicyContentType.ParkingGuide,
      }).unwrap();
    } else {
      const _response = await updateContent({
        ...data!,
        content: newContent,
      }).unwrap();
    }
    closeEditContentModal();
  };

  return (
    <Container>
      <Appbar title={'Parking Guide'}>
        <AddNewCustomerButton
          onClick={() => openEditContentModal(data?.content)}
        >
          {isAddContentMode ? 'Add Content' : 'Edit Content'}
        </AddNewCustomerButton>
      </Appbar>

      {data ? <div style={{ padding: 30 }}>{parse(data.content)}</div> : null}

      {isEditContentOpen ? (
        <Modal
          handleClose={closeEditContentModal}
          label={isAddContentMode ? 'Add Content' : 'Edit Content'}
          open={isEditContentOpen}
          width={1000}
        >
          <EditContentModal
            content={editContentItem}
            onSave={handleSaveContent}
          />
        </Modal>
      ) : null}
    </Container>
  );
}
