export enum VehicleReportStatus {
  Unknown = 0,
  Owner = 1,
  Requested = 2,
  Granted = 3,
}

export interface IVehicleReport {
  id: string;
  username: string;
  status: VehicleReportStatus;
  registrationNumber: string;
  artEndDate: Date | string | null;
  co2Emissions: number;
  engineCapacity: number;
  fuelType: string;
}

export const vehicleReportStatusValues: Record<VehicleReportStatus, string> = {
  [VehicleReportStatus.Unknown]: 'Unknown',
  [VehicleReportStatus.Owner]: 'Owner',
  [VehicleReportStatus.Requested]: 'Requested',
  [VehicleReportStatus.Granted]: 'Granted',
};
