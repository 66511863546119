import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface IProps {
  value: string;
}

export default function CopyColumn(props: IProps) {
  return (
    <Tooltip title={props.value}>
      <>
        <span className='table-cell-truncate'>{props.value}</span>
        <ContentCopyIcon
          fontSize='small'
          style={{ cursor: 'pointer', marginLeft: 'auto' }}
          onClick={() => {
            navigator.clipboard.writeText(props.value).then(() => {
              toast.success('Copied to clipboard');
            });
          }}
        />
      </>
    </Tooltip>
  );
}
