import { GridColDef } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { ExtendedGridColDef } from '@Types/ExtendedGridColDef';

import CopyColumn from '@Components/table/CopyColumn';
import { IUserReport } from '@Types/UserReport';
import { formatTime } from '@Utils/formatTime';

export const usersReportColumns: (GridColDef<IUserReport> &
  ExtendedGridColDef)[] = [
  {
    field: 'id',
    headerName: 'ID',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      return <CopyColumn value={params.value} />;
    },
    valueGetter: (params) => `${params.row.id}`,
  },
  {
    field: 'email',
    headerName: 'Email',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.email,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.phoneNumber,
  },
  {
    field: 'isActive',
    headerName: 'Active',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => (params.row.isActive ? 'Yes' : 'No'),
  },
  {
    field: 'isPhoneNumberVerified',
    headerName: 'Is Phone Num. Verified',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => (params.row.isPhoneNumberVerified ? 'Yes' : 'No'),
  },
  {
    field: 'isEmailVerified',
    headerName: 'Is Email Verified',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => (params.row.isEmailVerified ? 'Yes' : 'No'),
  },
  {
    field: 'hasAccountKey',
    headerName: 'Has Account Key',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => (params.row.isEmailVerified ? 'Yes' : 'No'),
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return <Tooltip title={value}>{value}</Tooltip>;
    },
    valueGetter: (params) => formatTime(params.row.createdAt),
  },
];
