import { useReactToPrint } from 'react-to-print';

import { styled, Box } from '@mui/material';

import useTableRequestParams from 'hooks/useTableRequestParams';
import Table from '@Components/table';
import { AppBarWithApiRef } from '@Components/app-bar';
import {
  useLazyVehicleReportQuery,
  useVehicleReportBySelectedIdMutation,
  useVehicleReportQuery,
} from '@Store/api/vehicleReportSlice';
import { vehicleReportColumns } from './columns';
import Papa from 'papaparse';
import { exportData } from '@Utils/export-csv';
import { useEffect } from 'react';
import { handleFetchError } from '@Utils/handleError';

const Container = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export function VehicleReport() {
  const tableParams = useTableRequestParams();

  const { data: vehicleReport, error: tableDataError } =
    useVehicleReportQuery(tableParams);

  const [getAllVehicleReport] = useLazyVehicleReportQuery();

  const [fetchSelectedVehiclesReport] = useVehicleReportBySelectedIdMutation();

  const handlePrint = useReactToPrint({
    content: () => document.getElementById('vehicle-report-container'),
  });

  const handleExport = async (selectedIds?: string[]) => {
    const dataToExport = [] as any[];

    if (selectedIds) {
      const history = await fetchSelectedVehiclesReport(selectedIds).unwrap();
      history?.forEach((data: any) => {
        dataToExport.push([
          ...vehicleReportColumns.map((column) =>
            column.valueGetter!({ row: data } as any)
          ),
        ]);
      });
    } else {
      const history = await getAllVehicleReport({
        getAllPages: true,
      } as any).unwrap();
      history?.items?.forEach((data) => {
        dataToExport.push([
          ...vehicleReportColumns.map((column) =>
            column.valueGetter!({ row: data } as any)
          ),
        ]);
      });
    }
    const csv = Papa.unparse({
      fields: vehicleReportColumns.map((column) => column.headerName!),
      data: dataToExport,
    });
    exportData(csv);
  };

  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <Container id='vehicle-report-container'>
      <Table
        checkboxSelection
        printable
        searchable
        columns={[...vehicleReportColumns]}
        handleExport={handleExport}
        handlePrint={handlePrint}
        paginated={
          vehicleReport?.items.length ? vehicleReport.items.length > 0 : false
        }
        rows={vehicleReport?.items || []}
        toolbar={AppBarWithApiRef}
        toolbarTitle='Vehicle Report'
        totalCount={vehicleReport?.count}
      />
    </Container>
  );
}
