import { GridColDef } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { formatTime } from '@Utils/formatTime';
import { ExtendedGridColDef } from '@Types/ExtendedGridColDef';
import {
  INotificationHistory,
  notificationHistoryTypeValues,
} from '@Types/NotificationHistory';
import CopyColumn from '@Components/table/CopyColumn';

export const notificationsHistoryColumns: (GridColDef<INotificationHistory> &
  ExtendedGridColDef)[] = [
  {
    field: 'id',
    headerName: 'ID',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      return <CopyColumn value={params.value} />;
    },
    valueGetter: (params) => `${params.row.id}`,
  },
  {
    field: 'deviceId',
    headerName: 'Device ID',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      return params.value === null ? (
        'N/A'
      ) : (
        <CopyColumn value={params.value} />
      );
    },
    valueGetter: (params) =>
      params.row.deviceId === null ? null : `${params.row.deviceId}`,
  },
  {
    field: 'sentAt',
    headerName: 'Sent At',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => formatTime(params.row.sentAt),
  },
  {
    field: 'sentFrom',
    headerName: 'Sent From',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return <Tooltip title={value}>{value}</Tooltip>;
    },
    valueGetter: (params) => params.row.sentFrom,
  },
  {
    field: 'sentTo',
    headerName: 'Sent To',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.sentTo,
  },
  {
    field: 'notificationType',
    headerName: 'Notification Type',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const dateStr = params.value;

      return (
        <Tooltip title={dateStr}>
          <span className='table-cell-truncate'>{dateStr}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) =>
      notificationHistoryTypeValues[params.row.notificationType],
  },
];
