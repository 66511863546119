import { AppBarWithApiRef } from '@Components/app-bar';
import Table from '@Components/table';
import {
  useLazyUsersReportQuery,
  useUsersReportBySelectedIdMutation,
  useUsersReportQuery,
} from '@Store/api/usersSlice';
import { exportData } from '@Utils/export-csv';
import { styled, Box } from '@mui/material';
import useTableRequestParams from 'hooks/useTableRequestParams';
import Papa from 'papaparse';
import { useReactToPrint } from 'react-to-print';
import { usersReportColumns } from './columns';
import { useEffect } from 'react';
import { handleFetchError } from '@Utils/handleError';

const Container = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export function UserReport() {
  const tableParams = useTableRequestParams();

  const { data: usersReport, error: tableDataError } =
    useUsersReportQuery(tableParams);

  const [fetchSelectedUsersReport] = useUsersReportBySelectedIdMutation();
  const [getAllUsersReport] = useLazyUsersReportQuery();

  const handlePrint = useReactToPrint({
    content: () => document.getElementById('user-report-container'),
  });

  const handleExport = async (selectedIds?: string[]) => {
    const dataToExport = [] as any[];

    if (selectedIds) {
      const history = await fetchSelectedUsersReport(selectedIds).unwrap();
      history?.forEach((data: any) => {
        dataToExport.push([
          ...usersReportColumns.map((column) =>
            column.valueGetter!({ row: data } as any)
          ),
        ]);
      });
    } else {
      const history = await getAllUsersReport({
        getAllPages: true,
      } as any).unwrap();
      history?.items?.forEach((data) => {
        dataToExport.push([
          ...usersReportColumns.map((column) =>
            column.valueGetter!({ row: data } as any)
          ),
        ]);
      });
    }

    const csv = Papa.unparse({
      fields: usersReportColumns.map((column) => column.headerName!),
      data: dataToExport,
    });
    exportData(csv);
  };

  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <Container id='user-report-container'>
      <Table
        checkboxSelection
        printable
        searchable
        columns={[...usersReportColumns]}
        handleExport={handleExport}
        handlePrint={handlePrint}
        paginated={
          usersReport?.items?.length ? usersReport.items.length > 0 : false
        }
        rows={usersReport?.items || []}
        toolbar={AppBarWithApiRef}
        toolbarTitle='User Report'
        totalCount={usersReport?.count}
      />
    </Container>
  );
}
