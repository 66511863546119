import { useState } from 'react';

import { Box, Divider, styled } from '@mui/material';

import Logo from '../../assets/images/navbar-logo.png';

import HideNavbarIcon from '../../assets/icons/hide-navbar.svg';
import OpenNavbarIcon from '../../assets/icons/open-navbar.svg';
import LogOutIcon from '../../assets/icons/log-out.svg';

import MenuItems from './MenuItems';
import { useDispatch } from 'react-redux';
import { logout } from '@Store/slices/auth';

interface INavbarStyleProps {
  isNavbarOpen: boolean;
}

const Container = styled(Box)<INavbarStyleProps>(({ theme, isNavbarOpen }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: isNavbarOpen ? 315 : 110,
  maxWidth: isNavbarOpen ? 315 : 110,
  padding: 30,
  paddingTop: 0,
  minHeight: '100vh',
  height: '100vh',
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.primary.main,
  transition: 'min-width 0.3s ease, max-width 0.3s ease, padding 0.3s ease',

  img: {
    cursor: 'pointer',
  },
}));

const LogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 164px;
`;

const StyledDivider = styled(Divider)`
  background: #fff;
  opacity: 0.2;
`;

const LogOutContainer = styled(Box)`
  display: flex;
  margin-top: auto;
  color: #fff;
  font-size: 18px;
  cursor: pointer;

  span {
    margin-left: 20px;
  }
`;

const Navbar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(true);

  const dispatch = useDispatch();

  const handleLogOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    dispatch(logout());
  };

  return (
    <Container isNavbarOpen={isNavbarOpen}>
      <LogoContainer>
        {isNavbarOpen ? (
          <>
            <img alt={'Logo'} src={Logo} />
            <img
              alt={'Hide navbar'}
              src={HideNavbarIcon}
              onClick={() => setIsNavbarOpen(false)}
            />
          </>
        ) : (
          <img
            alt={'Open navbar'}
            src={OpenNavbarIcon}
            onClick={() => setIsNavbarOpen(true)}
          />
        )}
      </LogoContainer>
      <StyledDivider />
      <MenuItems isNavbarOpen={isNavbarOpen} />
      <LogOutContainer onClick={handleLogOut}>
        <img alt='Log out' src={LogOutIcon} />
        {isNavbarOpen ? <span>Log out</span> : null}
      </LogOutContainer>
    </Container>
  );
};

export default Navbar;
