import { Box, styled } from '@mui/material';

import { Appbar } from '@Components/app-bar';
import Table from '@Components/table';
import { notificationsColumns } from './columns';
import { INotification } from '@Types/Notifications';
import useActionColumn from '@Components/table/useActionColumn';
import useModal from 'hooks/useModal';
import { Modal } from '@Components/modal';
import { NotificationDetails } from './notification-details/NotificationDetails.container';
import { useGetNotificationsQuery } from '@Store/api/notificationsSlice';
import useTableRequestParams from 'hooks/useTableRequestParams';
import { useEffect } from 'react';
import { handleFetchError } from '@Utils/handleError';

const Container = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export function Notifications() {
  const talbeParams = useTableRequestParams();
  const { data, error: tableDataError } = useGetNotificationsQuery(talbeParams);

  const {
    isOpen: isNotificationDetailsOpen,
    item: notification,
    openModal: openNotificationDetails,
    closeModal: closeNotificationDetails,
  } = useModal<INotification>();

  const handleShowDetails = (notification: INotification) => {
    openNotificationDetails(notification);
  };

  const actionColumn = useActionColumn({
    handleDetails: handleShowDetails,
  });

  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <Container>
      <Appbar searchable title={'Notifications'} />
      <Table
        columns={[...notificationsColumns, actionColumn]}
        rows={data?.items || []}
        totalCount={data?.count}
      />

      <Modal
        handleClose={closeNotificationDetails}
        label={'User Notifications'}
        open={isNotificationDetailsOpen}
      >
        <NotificationDetails
          handleClose={closeNotificationDetails}
          notification={notification}
        />
      </Modal>
    </Container>
  );
}
