export const DASHBOARD_PAGE = '/';
export const USERS_PAGE = '/users';

export const MANAGEMENT_PAGE = '/management';
export const NOTIFICATIONS_PAGE = '/notifications';
export const SPACE_TARRIF_MAP_PAGE = '/space-tarrif-map';
export const SPACE_TARRIF_MAP_NEW_CUSTOMER_PAGE =
  '/space-tarrif-map/new-customer';

export const SPACE_TARRIF_MAP_EDIT_CUSTOMER_PAGE =
  '/space-tarrif-map/customer/:id';

export const REPORTING_PAGE = '/reporting';
export const USER_REPORT_PAGE = '/user-reports';
export const CUSTOMER_REPORT_PAGE = '/customer-report';
export const VEHICLE_REPORT_PAGE = '/vehicle-report';
export const SPACE_TARRIF_REPORT_PAGE = '/space-tarrif-report';
export const PAYMENT_HISTORY_PAGE = '/payment-history';
export const NOTIFICATIONS_HISTORY_PAGE = '/notifications-history';
export const SESSION_PAGE = '/sessions';

export const POLICIES_PAGE = '/policies';
export const FAQ_PAGE = '/faq';
export const TERMS_AND_CONDITIONS_PAGE = '/terms-and-conditions';
export const PRIVACY_POLICIES_PAGE = '/privacy-policy';
export const PARKING_GUIDE_PAGE = '/parking-guide';

export const LOGIN_PAGE = '/auth';
export const RECOVER_PASSWORD_PAGE = '/auth/recover-password';
export const FORGOT_PASSWORD_PAGE = '/auth/forgot-password';
export const TYPE_PASSCODE_PAGE = '/auth/type-passcode';
