/* eslint-disable react/jsx-props-no-spreading */
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '@mui/material/Button';
import { Typography, styled } from '@mui/material';

import LoginBoxWrapper from '@Components/layouts/login-box-wrapper';
import FormInput from 'UI/Form/FormInput';

import { LOGIN_PAGE, TYPE_PASSCODE_PAGE } from '@Routes/constants';

import resolver, { IForgotPassword } from './ForgotPassword.validation';
import { useForgotPasswordMutation } from '@Store/api/loginSlice';

const FormHelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  cursor: 'pointer',
  marginTop: 30,
  marginBottom: 20,
}));

export function ForgotPassword() {
  const navigate = useNavigate();
  const [forgotPassword] = useForgotPasswordMutation();

  const methods = useForm<IForgotPassword>({
    resolver,
  });
  const { handleSubmit } = methods;

  const handleFormSubmit: SubmitHandler<IForgotPassword> = async (
    data
  ): Promise<void> => {
    try {
      await forgotPassword({ username: data.email }).unwrap();
      toast.success('OTP code sent successfully');
      navigate(TYPE_PASSCODE_PAGE, {
        state: {
          username: data.email,
          navigateToTheRecoverPassword: true,
        },
      });
    } catch (err: any) {
      const message =
        err?.data?.message || err?.message || 'Something went wrong...';
      toast.error(message);
    }
  };

  const handleFogotPasswordClicked = () => {
    navigate(LOGIN_PAGE);
  };

  return (
    <LoginBoxWrapper title='Forgot password?'>
      <FormProvider {...methods}>
        <form
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <div style={{ marginTop: 30 }}>
            <FormInput label='Email' name='email' />
          </div>
          <Button
            fullWidth
            sx={{ mt: 'auto' }}
            type='submit'
            variant='contained'
          >
            Recover password
          </Button>
        </form>
        <FormHelperText onClick={handleFogotPasswordClicked}>
          Already have an account? <b>Log in</b>
        </FormHelperText>
      </FormProvider>
    </LoginBoxWrapper>
  );
}
