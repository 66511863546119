import { ITableRequestParams } from '@Types/Table';
import { apiSlice } from './apiSlice';
import { IGenericListModel } from '@Types/GenericListModel';
import { ISession } from '@Types/Sessions';

const sessionsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sessions: builder.query<IGenericListModel<ISession>, ITableRequestParams>({
      query: ({ sortFields, search, pageSize, currentPage, getAllPages }) => ({
        url: '/api/Sessions/GetAll',
        method: 'POST',
        body: {
          sortFields,
          search,
          pageSize,
          currentPage,
          getAllPages,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: ['sessions'] as any,
    }),
    sessionsBySelectedId: builder.mutation<ISession[], string[]>({
      query: (data) => ({
        url: '/api/Sessions/GetSessionsById',
        method: 'POST',
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useSessionsQuery,
  useLazySessionsQuery,
  useSessionsBySelectedIdMutation,
} = sessionsSlice;
