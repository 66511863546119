import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

interface ICoordinate {
  lat: string;
  lng: string;
}
interface IPolygonData {
  subAreaId: string;
  subareaName: string;
  configId: string;
  defaultTime: number;
  reminderFee?: number;
  convenienceFee?: number;
  coordinates: ICoordinate[];
  areaMap?: string;
  hasVat?: boolean;
}

export interface IPolygonFormData {
  id?: string;
  name: string;
  vatNumber: string;
  defaultReminderFee: number;
  defaultConvenienceFee: number;
  merchantId: string;
  selections: IPolygonData[];
}

const validationSchema = yup.object<IPolygonFormData>().shape({
  name: yup.string().required('Customer Name is required'),
  vatNumber: yup.string(),
  defaultReminderFee: yup.number().required('Reminder Fee is required'),
  defaultConvenienceFee: yup.number().required('Convenience Fee is required'),
  merchantId: yup.string().required('Merchant ID is required'),
  selections: yup.array().of(
    yup.object().shape({
      subareaName: yup.string().required('Subarea Name is required'),
      subAreaId: yup.string().required('Subarea ID is required'),
      configId: yup.string().required('Config ID is required'),
      defaultTime: yup.number().required('Default Time (minutes) is required'),
      reminderFee: yup.number().nullable(),
      convenienceFee: yup.number().nullable(),
    })
  ),
});

export default yupResolver(validationSchema);
