/* eslint-disable react/jsx-props-no-spreading */
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '@mui/material/Button';
import { Typography, styled } from '@mui/material';

import LoginBoxWrapper from '@Components/layouts/login-box-wrapper';
import FormInput from 'UI/Form/FormInput';

import { LOGIN_PAGE } from '@Routes/constants';

import resolver, { IPasswordRecovery } from './RecoverPassword.validation';
import { useUpdatePasswordMutation } from '@Store/api/loginSlice';

const FormHelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  cursor: 'pointer',
  marginTop: 30,
  marginBottom: 20,
}));

export function RecoverPassword() {
  const navigate = useNavigate();

  const location = useLocation();

  const [updatePassword] = useUpdatePasswordMutation();

  const methods = useForm<IPasswordRecovery>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver,
  });
  const { handleSubmit } = methods;

  const handleFormSubmit: SubmitHandler<IPasswordRecovery> = async (
    data
  ): Promise<void> => {
    try {
      const { username } = location.state;

      await updatePassword({
        password: data.password,
        confirmPassword: data.confirmPassword,
        username: username,
      }).unwrap();

      toast.success('Password successfully updated');

      navigate(LOGIN_PAGE);
    } catch (err: any) {
      const message =
        err?.data?.message || err?.message || 'Something went wrong...';
      toast.error(message);
    }
  };

  const handleAlreadyHaveAccountClicked = () => {
    navigate(LOGIN_PAGE);
  };

  return (
    <LoginBoxWrapper title='Password Recovery'>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div style={{ marginTop: 30 }}>
            <FormInput label='New password' name='password' type='password' />
          </div>
          <div style={{ marginTop: 30 }}>
            <FormInput
              label='Confirm new password'
              name='confirmPassword'
              type='password'
            />
          </div>
          <Button
            fullWidth
            sx={{ mt: '30px' }}
            type='submit'
            variant='contained'
          >
            Save Password
          </Button>
        </form>
        <FormHelperText onClick={handleAlreadyHaveAccountClicked}>
          Already have an account? <b>Log in</b>
        </FormHelperText>
      </FormProvider>
    </LoginBoxWrapper>
  );
}
