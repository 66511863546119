/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, styled } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import resolver from './CreateOrEditQuestionForm.validation';
import FormInput from 'UI/Form/FormInput';
import {
  useCreateFAQMutation,
  useUpdateFAQMutation,
} from '@Store/api/policiesSlice';
import { ICreateOrEditFAQ, IFAQ } from '@Types/FAQ';

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;

  & .MuiFormHelperText-root {
    color: red !important;
  }
`;

interface IProps {
  FAQ?: IFAQ;
  readOnly?: boolean;
  handleClose: () => void;
}

export function CreateOrEditQuestionForm({
  FAQ,
  readOnly,
  handleClose,
}: IProps) {
  const [createFAQ] = useCreateFAQMutation();
  const [updateFAQ] = useUpdateFAQMutation();

  const methods = useForm<ICreateOrEditFAQ>({
    defaultValues: {
      question: FAQ?.question ?? '',
      answer: FAQ?.answer ?? '',
    },
    resolver,
  });
  const { handleSubmit } = methods;

  const handleFormSubmit: SubmitHandler<ICreateOrEditFAQ> = async (
    data
  ): Promise<void> => {
    if (FAQ) {
      const _response = await updateFAQ({
        id: FAQ.id,
        ...data,
      }).unwrap();
    } else {
      const _response = await createFAQ(data).unwrap();
    }
    handleClose();
  };

  return (
    <FormProvider {...methods}>
      <FormContainer
        component={'form'}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div style={{ marginTop: 0 }}>
          <FormInput
            autoHeight
            multiline
            label='Question'
            name='question'
            readOnly={readOnly}
            rows={4}
            type='text'
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <FormInput
            autoHeight
            multiline
            label='Answer'
            name='answer'
            readOnly={readOnly}
            rows={4}
            type='text'
          />
        </div>
        {!readOnly && (
          <Button
            fullWidth
            sx={{ mt: '30px', textTransform: 'capitalize', fontSize: 16 }}
            type='submit'
            variant='contained'
          >
            {FAQ ? 'Save Changes' : 'Add New Question'}
          </Button>
        )}
      </FormContainer>
    </FormProvider>
  );
}
