import { Shadows, createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: 'Gotham, Arial, sans-serif',
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  palette: {
    primary: {
      main: '#00535E',
    },
    secondary: {
      main: '#FCB525',
    },
    background: {
      paper: '#F6F5F5',
    },
    error: {
      main: '#A91D54',
    },
  },
  shape: {
    borderRadius: 20,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { minHeight: 60 },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: { minHeight: 60 },
      },
    },
  },
  shadows: Array(25).fill('none') as Shadows,
});
