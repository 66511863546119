import { IUser } from '@Store/slices/auth';
import { GridColDef } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { ExtendedGridColDef } from '@Types/ExtendedGridColDef';
import CopyColumn from '@Components/table/CopyColumn';

export const userColumns: (GridColDef<IUser> & ExtendedGridColDef)[] = [
  {
    field: 'id',
    headerName: 'User ID',
    hideable: false,
    flex: 0.2,
    renderCell: (params) => {
      return <CopyColumn value={params.value} />;
    },
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone umber',
    hideable: false,
    flex: 0.2,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span className='table-cell-truncate'>{params.row.phoneNumber}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    hideable: false,
    flex: 0.2,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span className='table-cell-truncate'>{params.value}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'isActive',
    headerName: 'Status',
    hideable: false,
    flex: 0.2,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value ? 'Active' : 'Inactive'}>
          <span className='table-cell-truncate'>
            {params.value ? 'Active' : 'Inactive'}
          </span>
        </Tooltip>
      );
    },
  },
  {
    field: 'roleName',
    headerName: 'Role',
    hideable: false,
    flex: 0.2,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span className='table-cell-truncate'>
            {params.row.roles.map((role) => role.name).join(',')}
          </span>
        </Tooltip>
      );
    },
  },
];
