import { useReactToPrint } from 'react-to-print';
import { styled, Box } from '@mui/material';

import { AppBarWithApiRef } from '@Components/app-bar';
import Table from '@Components/table';

import useTableRequestParams from 'hooks/useTableRequestParams';

import {
  useLazyNotificationsHistoryQuery,
  useNotificationsHistoryBySelectedIdMutation,
  useNotificationsHistoryQuery,
} from '@Store/api/notificationsHistorySlice';
import { notificationsHistoryColumns } from './columns';
import Papa from 'papaparse';
import { exportData } from '@Utils/export-csv';
import { useEffect } from 'react';
import { handleFetchError } from '@Utils/handleError';

const Container = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export default function NotificationsHistory() {
  const tableParams = useTableRequestParams();

  const { data: notificationsHistory, error: tableDataError } =
    useNotificationsHistoryQuery(tableParams);

  const [getAllNotificationsHistory] = useLazyNotificationsHistoryQuery();

  const [fetchSelectedNotifications] =
    useNotificationsHistoryBySelectedIdMutation();

  const handlePrint = useReactToPrint({
    content: () => document.getElementById('notifications-history-container'),
  });

  const handleExport = async (selectedIds?: string[]) => {
    const dataToExport = [] as any[];

    if (selectedIds) {
      const history = await fetchSelectedNotifications(selectedIds).unwrap();
      history.forEach((data) => {
        dataToExport.push([
          ...notificationsHistoryColumns.map((column) =>
            column.valueGetter!({ row: data } as any)
          ),
        ]);
      });
    } else {
      const history = await getAllNotificationsHistory({
        getAllPages: true,
      } as any).unwrap();
      history?.items?.forEach((data) => {
        dataToExport.push([
          ...notificationsHistoryColumns.map((column) =>
            column.valueGetter!({ row: data } as any)
          ),
        ]);
      });
    }

    const csv = Papa.unparse({
      fields: notificationsHistoryColumns.map((column) => column.headerName!),
      data: dataToExport,
    });

    exportData(csv);
  };

  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <Container id='notifications-history-container'>
      <Table
        checkboxSelection
        printable
        searchable
        columns={[...notificationsHistoryColumns]}
        handleExport={handleExport}
        handlePrint={handlePrint}
        paginated={
          notificationsHistory?.items.length
            ? notificationsHistory.items.length > 0
            : false
        }
        rows={notificationsHistory?.items || []}
        toolbar={AppBarWithApiRef}
        toolbarTitle='Notifications History'
        totalCount={notificationsHistory?.count}
      />
    </Container>
  );
}
