import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export interface ILogin {
  username: string;
  password: string;
}

const schema = yup.object<ILogin>().shape({
  username: yup.string().required('Invalid Login'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .max(12, 'Password cannot exceed more than 12 characters'),
});

export default yupResolver(schema);
