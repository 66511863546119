import { Button } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { useState } from 'react';

const TINYMCE_API_KEY = process.env.REACT_APP_TINYMCE_API_KEY;

interface IProps {
  content: string;
  loading?: boolean;
  onSave: (content: string) => void;
}

export function EditContentModal({
  content: initialState,
  loading,
  onSave,
}: IProps) {
  const [content, setContent] = useState(initialState);

  const handleSaveChanges = () => {
    onSave(content);
  };

  return (
    <>
      <Editor
        apiKey={TINYMCE_API_KEY}
        init={{
          height: 500,
          menubar: false,
          plugins:
            'advlist anchor autolink codesample fullscreen help image tinydrive' +
            ' lists link media preview' +
            ' searchreplace table template visualblocks wordcount',
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
        value={content}
        onEditorChange={(e) => setContent(e)}
      />
      <Button
        disabled={loading}
        sx={{
          marginTop: '30px',
          width: '100%',
        }}
        type='submit'
        variant='contained'
        onClick={handleSaveChanges}
      >
        Save Changes
      </Button>
    </>
  );
}
