import React, { useCallback, useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { debounce } from '@mui/material';

import { InputAdornment, Menu, MenuItem, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { useSearchParams } from 'react-router-dom';
import { useGridApiContext } from '@mui/x-data-grid';
import { toast } from 'react-toastify';

export type ExportTypes = 'selected' | 'page' | 'all-data' | 'print';

const options: { value: ExportTypes; label: string }[] = [
  { value: 'selected', label: 'Export Selected' },
  { value: 'page', label: 'Export Page' },
  { value: 'all-data', label: 'Export All Data' },
  { value: 'print', label: 'Print' },
];

const PrintIcon = () => (
  <svg
    fill='none'
    height='60'
    style={{ cursor: 'pointer' }}
    viewBox='0 0 60 60'
    width='60'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='30' cy='30' fill='white' r='30' />
    <circle cx='30' cy='30' r='29.5' stroke='#181818' stroke-opacity='0.1' />
    <path
      d='M40.5 32.52V38.4C40.5 38.957 40.2787 39.4911 39.8849 39.8849C39.4911 40.2787 38.957 40.5 38.4 40.5H21.6C21.043 40.5 20.5089 40.2787 20.1151 39.8849C19.7212 39.4911 19.5 38.957 19.5 38.4V32.52C19.5 32.1858 19.6327 31.8653 19.869 31.629C20.1053 31.3927 20.4258 31.26 20.76 31.26C21.0942 31.26 21.4147 31.3927 21.651 31.629C21.8873 31.8653 22.02 32.1858 22.02 32.52V37.98H37.98V32.52C37.98 32.1858 38.1127 31.8653 38.349 31.629C38.5853 31.3927 38.9058 31.26 39.24 31.26C39.5742 31.26 39.8947 31.3927 40.131 31.629C40.3672 31.8653 40.5 32.1858 40.5 32.52ZM29.1086 33.4115C29.2256 33.5289 29.3647 33.6221 29.5179 33.6857C29.671 33.7493 29.8352 33.782 30.001 33.782C30.1669 33.782 30.3311 33.7493 30.4842 33.6857C30.6374 33.6221 30.7765 33.5289 30.8935 33.4115L35.0935 29.2114C35.3303 28.9747 35.4632 28.6537 35.4632 28.3189C35.4632 27.9842 35.3303 27.6632 35.0935 27.4264C34.8568 27.1897 34.5358 27.0568 34.201 27.0568C33.8663 27.0568 33.5453 27.1897 33.3085 27.4264L31.26 29.475V20.76C31.26 20.4258 31.1272 20.1053 30.891 19.869C30.6547 19.6327 30.3342 19.5 30 19.5C29.6658 19.5 29.3453 19.6327 29.109 19.869C28.8727 20.1053 28.74 20.4258 28.74 20.76V29.475L26.6914 27.4285C26.4547 27.1918 26.1337 27.0589 25.7989 27.0589C25.4642 27.0589 25.1432 27.1918 24.9064 27.4285C24.6697 27.6653 24.5368 27.9863 24.5368 28.321C24.5368 28.6558 24.6697 28.9768 24.9064 29.2135L29.1086 33.4115Z'
      fill='#00535E'
    />
  </svg>
);

const StyledSearchInput = styled(TextField)`
  .MuiInputBase-root {
    border-radius: 30px;
    background: #fff;
    margin-left: 10px;
    min-width: 550px;
  }
`;

const SearchInput = ({ onChange }: any) => {
  return (
    <StyledSearchInput
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      placeholder='Search...'
      sx={{
        width: 550,
      }}
      variant='outlined'
      onChange={onChange}
    />
  );
};

const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Container = styled(Box)`
  height: 120px;
`;

const StyledAppBar = styled(AppBar)`
  display: flex;
  justify-content: center;
  height: 120px;

  background-color: #f6f5f5;
  box-shadow: unset;
  border-bottom: 1px solid rgba(24, 24, 24, 0.05);

  .MuiToolbar-root {
    padding: 0 30px;
  }
`;

interface IProps {
  title: string;
  children?: React.ReactNode;
  searchable?: boolean;
  subTitle?: string;
  printable?: boolean;
  apiRef?: any;
  handlePrint?: () => void;
  handleExport?: (selectedIds?: string[]) => void;
}

export function Appbar({
  title,
  subTitle,
  children,
  searchable,
  printable,
  apiRef,
  handlePrint,
  handleExport,
}: IProps) {
  const [anchorEl, setAnchorEl] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchTextChange = useCallback(
    debounce((text: string) => {
      handleSearchTextChange(text);
    }, 500),
    []
  );

  const handleSearchTextChange = (text: string) => {
    searchParams.set('search', text);
    setSearchParams(searchParams, { replace: true });
  };

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onOptionClick = (type: ExportTypes) => {
    switch (type) {
      case 'page':
        handleExport!(apiRef.current.getAllRowIds());
        break;

      case 'selected': {
        const selectedEntityIds = [...apiRef.current.getSelectedRows().keys()];

        if (!selectedEntityIds.length) {
          toast.error('Please, select some record(s)');

          return;
        }

        handleExport!(selectedEntityIds);
        break;
      }
      case 'print':
        handlePrint!();
        break;

      case 'all-data': {
        handleExport!();
        break;
      }
    }
  };

  useEffect(() => {
    return () => {
      if (debouncedSearchTextChange?.clear) {
        debouncedSearchTextChange.clear();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <StyledAppBar position='static'>
        <Toolbar sx={{ padding: '0 30px' }}>
          <TitleContainer>
            <Typography
              sx={{ color: '#000', fontSize: 36, fontWeight: 700 }}
              variant='h6'
            >
              {title}
            </Typography>
            {subTitle ? (
              <Typography
                sx={{ color: '#000', fontSize: 16, fontWeight: 400 }}
                variant='body2'
              >
                {subTitle}
              </Typography>
            ) : null}
          </TitleContainer>
          {children}
          {printable ? (
            <div>
              <Tooltip title='Export'>
                <div onClick={handleOpenMenu}>
                  <PrintIcon />
                </div>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                sx={{
                  marginTop: 1,
                }}
                onClose={handleCloseMenu}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.label}
                    sx={{
                      justifyContent: 'space-between',
                      minWidth: 220,
                    }}
                    onClick={() => onOptionClick(option.value)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          ) : null}
          {searchable ? (
            <SearchInput
              onChange={(e: any) => debouncedSearchTextChange(e.target.value)}
            />
          ) : null}
        </Toolbar>
      </StyledAppBar>
    </Container>
  );
}

export const AppBarWithApiRef = (props: any) => {
  const apiRef = useGridApiContext();

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Appbar apiRef={apiRef} {...props} />;
};
