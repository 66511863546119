import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export interface ITypePasscode {
  code: string;
}

const schema = yup.object<ITypePasscode>().shape({
  code: yup.string().required('Passcode is required'),
});

export default yupResolver(schema);
