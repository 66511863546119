import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Outlet, useNavigate } from 'react-router-dom';

import Navbar from '@Components/navbar';

import { selectIsLoggedIn } from '@Store/slices/auth/selectors';
import { LOGIN_PAGE } from '@Routes/constants';

const DashboardLayout = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(LOGIN_PAGE);
    }
  }, [isLoggedIn, navigate]);

  return (
    <div style={{ display: 'flex' }}>
      <Navbar />
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
