import { ITableRequestParams } from '@Types/Table';
import { apiSlice } from './apiSlice';
import { IGenericListModel } from '@Types/GenericListModel';
import { IVehicleReport } from '@Types/VehicleReport';

const paymentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    vehicleReport: builder.query<
      IGenericListModel<IVehicleReport>,
      ITableRequestParams
    >({
      query: ({ sortFields, search, pageSize, currentPage, getAllPages }) => ({
        url: '/api/Vehicles/GetAllVehicles',
        method: 'POST',
        body: {
          sortFields,
          search,
          pageSize,
          currentPage,
          getAllPages,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: ['vehicle-report'] as any,
    }),
    vehicleReportBySelectedId: builder.mutation<IVehicleReport[], string[]>({
      query: (data) => ({
        url: '/api/Vehicles/GetVehiclesById',
        method: 'POST',
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useVehicleReportQuery,
  useLazyVehicleReportQuery,
  useVehicleReportBySelectedIdMutation,
} = paymentsApi;
