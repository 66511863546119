/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, styled } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import resolver, { ICreateOrEditUser } from './CreateOrEditUserForm.validation';
import FormInput from 'UI/Form/FormInput';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IUser } from '@Store/slices/auth';
import {
  useCreateUserMutation,
  useGerUserRolesQuery,
  useUpdateUserMutation,
} from '@Store/api/usersSlice';
import FormError from 'UI/Form/FormError';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { handleFetchError } from '@Utils/handleError';

function isEmail(value: string) {
  const emailRegex = /^\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/;

  return emailRegex.test(value);
}

function isPhoneNumber(value: string) {
  const phoneRegex = /^\+\d{1,15}/;

  return phoneRegex.test(value);
}

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;

  & .MuiFormHelperText-root {
    color: red !important;
  }
`;

interface IProps {
  user?: IUser;
  handleClose: () => void;
}

export function CreateOrEditUserForm({ user, handleClose }: IProps) {
  const { data: roles, error: tableDataError } = useGerUserRolesQuery();

  const [createUser] = useCreateUserMutation();

  const [updateUser] = useUpdateUserMutation();

  const methods = useForm<ICreateOrEditUser>({
    defaultValues: {
      phoneNumber: user?.phoneNumber ?? '',
      email: user?.email ?? '',
      isActive: user?.isActive ?? true,
      roleIds: user?.roles ? user.roles.map((role) => role.id) : [],
    },
    resolver,
  });
  const { formState, handleSubmit, watch, setValue, clearErrors, setError } =
    methods;

  const isActive = watch('isActive');
  const roleIds = watch('roleIds');

  useEffect(() => {
    if (roleIds.length > 0) clearErrors('roleIds');
  }, [roleIds, clearErrors]);

  const handleStatusChange = (event: SelectChangeEvent) => {
    setValue('isActive', !!event.target.value);
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    setValue('roleIds', event.target.value as any);
  };

  const handleFormSubmit: SubmitHandler<ICreateOrEditUser> = async (
    _data
  ): Promise<void> => {
    const data: any = { ..._data };
    const { phoneNumber = '', email = '' } = _data;

    if (isPhoneNumber(phoneNumber)) {
      data['phoneNumber'] = phoneNumber;
      data['isPhoneNumberVerified'] = true;
      data['isEmailVerified'] = false;
    } else if (isEmail(email)) {
      data['email'] = email;
      data['isPhoneNumberVerified'] = false;
      data['isEmailVerified'] = true;
    } else {
      setError('phoneNumber', { message: '' });
      setError('email', { message: '' });
      toast.error('Invalid phone number or email');

      return;
    }

    if (user) {
      await updateUser({
        ...data,
        id: user.id,
      }).unwrap();
      toast.success('User has been updated successfully');
      handleClose();
    } else {
      await createUser(data).unwrap();
      toast.success('User has been created successfully');
      handleClose();
    }
  };

  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <FormProvider {...methods}>
      <FormContainer
        component={'form'}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div style={{ marginTop: 0 }}>
          <FormInput label='Phone Number' name='phoneNumber' type='text' />
        </div>
        <div style={{ marginTop: 30 }}>
          <FormInput label='Email' name='email' type='text' />
        </div>
        <div style={{ marginTop: 30 }}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-error-label'>
              Select status
            </InputLabel>
            <Select
              label='Select status'
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow: 'unset',
                    border: '1px solid #00535E',
                    marginTop: '10px',
                    padding: '0 5px',

                    li: {
                      borderRadius: 20,
                      padding: '8px 18px',
                    },
                  },
                },
              }}
              value={isActive ? '1' : '0'}
              onChange={handleStatusChange}
            >
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={0}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ marginTop: 30 }}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-error-label'>
              Select user role
            </InputLabel>
            <Select
              multiple
              error={
                formState.isSubmitted && roleIds.length === 0 ? true : false
              }
              label='Select user role'
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow: 'unset',
                    border: '1px solid #00535E',
                    marginTop: '10px',
                    padding: '0 5px',

                    li: {
                      borderRadius: 20,
                      marginTop: '5px',
                      padding: '8px 18px',
                    },
                  },
                },
              }}
              value={roleIds as any}
              onChange={handleRoleChange}
            >
              {!!roles
                ? roles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))
                : null}
            </Select>
            <FormError errors={formState.errors} name='roleIds' />
          </FormControl>
        </div>
        <Button
          fullWidth
          disabled={false}
          sx={{ mt: '30px', textTransform: 'capitalize', fontSize: 16 }}
          type='submit'
          variant='contained'
        >
          {user ? 'Edit User' : 'Add New User'}
        </Button>
      </FormContainer>
    </FormProvider>
  );
}
