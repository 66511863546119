import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUser {
  email: string;
  id: string;
  isActive: boolean;
  isEmailVerified: boolean;
  isPhoneNumberVerified: boolean;
  phoneNumber: string;
  roles: Array<{ id: string; name: 'Super Admin' }>;
}

export interface IAuthState {
  user: IUser | null;
}

const initialState: IAuthState = {
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    logout: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
