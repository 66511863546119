import { useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';

import { styled, Box } from '@mui/material';

import useTableRequestParams from 'hooks/useTableRequestParams';
import Table from '@Components/table';
import { AppBarWithApiRef } from '@Components/app-bar';
import { customerSelectionReportColumns } from './columns';
import Papa from 'papaparse';
import { exportData } from '@Utils/export-csv';
import {
  useCustomersSelectionsByIdMutation,
  useGetCustomersSelectionsQuery,
  useLazyGetCustomersSelectionsQuery,
} from '@Store/api/customersSlice';
import { handleFetchError } from '@Utils/handleError';

const Container = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export function SpaceTarrifReport() {
  const tableParams = useTableRequestParams();

  const { data: customerSelectionsReport, error: tableDataError } =
    useGetCustomersSelectionsQuery(tableParams);

  const [getAllCustomersSelections] = useLazyGetCustomersSelectionsQuery();

  const [fetchSelectedCustomersReport] = useCustomersSelectionsByIdMutation();

  const handlePrint = useReactToPrint({
    content: () =>
      document.getElementById('customers-selections-report-container'),
  });

  const handleExport = async (selectedIds?: string[]) => {
    const dataToExport = [] as any[];

    if (selectedIds) {
      const history = await fetchSelectedCustomersReport(selectedIds).unwrap();
      history?.forEach((data: any) => {
        dataToExport.push([
          ...customerSelectionReportColumns.map((column) =>
            column.valueGetter!({ row: data } as any)
          ),
        ]);
      });
    } else {
      const history = await getAllCustomersSelections({
        getAllPages: true,
      } as any).unwrap();
      history?.items?.forEach((data) => {
        dataToExport.push([
          ...customerSelectionReportColumns.map((column) =>
            column.valueGetter!({ row: data } as any)
          ),
        ]);
      });
    }
    const csv = Papa.unparse({
      fields: customerSelectionReportColumns.map(
        (column) => column.headerName!
      ),
      data: dataToExport,
    });
    exportData(csv);
  };

  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <Container id='customers-selections-report-container'>
      <Table
        checkboxSelection
        printable
        searchable
        columns={[...customerSelectionReportColumns]}
        handleExport={handleExport}
        handlePrint={handlePrint}
        paginated={
          customerSelectionsReport?.items.length
            ? customerSelectionsReport.items.length > 0
            : false
        }
        rows={customerSelectionsReport?.items || []}
        toolbar={AppBarWithApiRef}
        toolbarTitle='Space & Tariff Report'
        totalCount={customerSelectionsReport?.count}
      />
    </Container>
  );
}
