import { Appbar } from '@Components/app-bar';
import { Box, Button, styled } from '@mui/material';
import AddNewIcon from '../../assets/icons/add-new-user-icon.svg';
import { useNavigate } from 'react-router-dom';
import { SPACE_TARRIF_MAP_NEW_CUSTOMER_PAGE } from '@Routes/constants';
import {
  useDeleteCustomerMutation,
  useGetCustomersQuery,
} from '@Store/api/customersSlice';
import useTableRequestParams from 'hooks/useTableRequestParams';
import { customerColumns } from './columns';
import Table from '@Components/table';
import useActionColumn from '@Components/table/useActionColumn';
import { useCallback, useEffect } from 'react';
import useModal from 'hooks/useModal';
import { Modal } from '@Components/modal';
import { DeleteCustomerContainer } from './DeleteCustomerContainer';
import { IPolygonFormData } from 'pages/space-tarrif-map-new-customer/CustomerForm.validation';
import { handleFetchError } from '@Utils/handleError';

const Container = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const AddNewCustomerButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: '18px 20px',
  borderRadius: 30,
  color: '#FFF',
  img: {
    marginRight: 10,
  },
  textTransform: 'capitalize',
  '&:hover': { backgroundColor: theme.palette.secondary.main },
}));

export function SpaceTarrifMap() {
  const {
    isOpen: isDeleteModalOpen,
    item: customerToDelete,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal<IPolygonFormData>();

  const navigate = useNavigate();
  const tableParams = useTableRequestParams();
  const { data: customers, error: tableDataError } =
    useGetCustomersQuery(tableParams);

  const [deleteCustomer] = useDeleteCustomerMutation();

  const handleOpenEditUser = useCallback(
    (customer: IPolygonFormData) => {
      navigate(`/space-tarrif-map/customer/${customer.id}`);
    },
    [navigate]
  );

  const handleOpenDeleteUser = useCallback(
    (customer: IPolygonFormData) => {
      openDeleteModal(customer);
    },
    [openDeleteModal]
  );

  const handleDelete = async () => {
    await deleteCustomer(customerToDelete.id!).unwrap();
    closeDeleteModal();
  };

  const actionColumn = useActionColumn({
    handleEdit: handleOpenEditUser,
    handleDelete: handleOpenDeleteUser,
  });

  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <Container>
      <Appbar searchable title={'Parking Space & Map'}>
        <AddNewCustomerButton
          onClick={() => navigate(SPACE_TARRIF_MAP_NEW_CUSTOMER_PAGE)}
        >
          <img alt='Add New Customer' src={AddNewIcon} />
          Add New Customer
        </AddNewCustomerButton>
      </Appbar>
      <Table
        columns={[...customerColumns, actionColumn]}
        rows={customers?.items || []}
        totalCount={customers?.count || 0}
      />

      {isDeleteModalOpen ? (
        <Modal
          labelErrored
          handleClose={closeDeleteModal}
          label={'Delete customer'}
          open={isDeleteModalOpen}
        >
          <DeleteCustomerContainer
            customer={customerToDelete}
            onDelete={handleDelete}
          />
        </Modal>
      ) : null}
    </Container>
  );
}
