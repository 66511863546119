import { format } from 'date-fns-tz';

// export const formatTime = (time: string | Date) =>
//   format(
//     utcToZonedTime(
//       new Date((time as string).includes('Z') ? time : `${time}Z`),
//       'Europe/London'
//     ),
//     'dd/MM/yyyy HH:mm'
//   );
export const formatTime = (time: string | Date) =>
  format(new Date(time), 'dd/MM/yyyy HH:mm');
