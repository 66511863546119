/* eslint-disable react/jsx-props-no-spreading */
import { useUpdateNotificationMutation } from '@Store/api/notificationsSlice';
import { INotification } from '@Types/Notifications';
import { Box, Button, Divider, styled, Typography } from '@mui/material';
import { SwitchButton } from '@UI/SwitchButton';
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';

const Details = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const DetailsTitle = styled(Typography)`
  color: #181818;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;

const DetailsValue = styled(Typography)`
  color: #181818;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const DetailsContainer = styled(Box)`
  margin-top: 30px;
  display: flex;
`;

interface IProps {
  notification: INotification;
  handleClose: () => void;
}

export function NotificationDetails({
  notification: data,
  handleClose,
}: IProps) {
  const [notification, setNotification] = useState(data);

  const [updateNotification] = useUpdateNotificationMutation();

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotification({
      ...notification,
      [event.target.name]: event.target.checked,
    });
  };

  const saveChanges = async () => {
    await updateNotification({
      ...notification,
      phoneNumber: notification?.phoneNumber ?? '',
    }).unwrap();
    handleClose();
  };

  return (
    <Box>
      <Details>
        <DetailsTitle>Device ID</DetailsTitle>
        <DetailsValue>{notification.deviceId}</DetailsValue>
      </Details>
      <Details sx={{ marginTop: '30px' }}>
        <DetailsTitle>User ID</DetailsTitle>
        <DetailsValue>{notification.userId}</DetailsValue>
      </Details>
      <DetailsContainer>
        <Details flexBasis={1} flexGrow={1}>
          <DetailsTitle>Token</DetailsTitle>
          <DetailsValue sx={{ display: 'flex' }}>
            <span
              className='table-cell-truncate'
              style={{ width: 200, display: 'block' }}
            >
              {notification.deviceToken}
            </span>
            <ContentCopyIcon
              fontSize='small'
              style={{ cursor: 'pointer', marginLeft: '5px' }}
              onClick={() => {
                navigator.clipboard
                  .writeText(notification.deviceToken)
                  .then(() => {
                    toast.success('Copied to clipboard');
                  });
              }}
            />
          </DetailsValue>
        </Details>
        <Details flexBasis={1} flexGrow={1} ml={1}>
          <DetailsTitle>Phone number</DetailsTitle>
          <DetailsValue>{notification.phoneNumber}</DetailsValue>
        </Details>
      </DetailsContainer>
      <DetailsContainer>
        <Details flexBasis={1} flexGrow={1}>
          <DetailsTitle>Status</DetailsTitle>
          <DetailsValue>
            {notification.isActive ? 'Active' : 'Inactive'}
          </DetailsValue>
        </Details>
        <Details flexBasis={1} flexGrow={1} ml={1}>
          <DetailsTitle>Email</DetailsTitle>
          <DetailsValue>{notification.email}</DetailsValue>
        </Details>
      </DetailsContainer>
      <Divider sx={{ mt: '30px' }} />
      <DetailsContainer flexDirection={'column'}>
        <Details
          justifyContent={'space-between'}
          sx={{ flexDirection: 'row' }}
          width={'100%'}
        >
          <DetailsTitle>Parking about to end</DetailsTitle>
          <DetailsValue>
            <SwitchButton
              checked={notification.isNotifyOnEnd}
              name='isNotifyOnEnd'
              sx={{ m: 1 }}
              onChange={handleSwitchChange}
            />
          </DetailsValue>
        </Details>
        <Details
          justifyContent={'space-between'}
          sx={{ flexDirection: 'row' }}
          width={'100%'}
        >
          <DetailsTitle>Marketing communication</DetailsTitle>
          <DetailsValue>
            <SwitchButton disabled sx={{ m: 1 }} />
          </DetailsValue>
        </Details>
        <Details
          justifyContent={'space-between'}
          sx={{ flexDirection: 'row' }}
          width={'100%'}
        >
          <DetailsTitle>Start session notification</DetailsTitle>
          <DetailsValue>
            <SwitchButton
              checked={notification.isNotifyOnStart}
              name='isNotifyOnStart'
              sx={{ m: 1 }}
              onChange={handleSwitchChange}
            />
          </DetailsValue>
        </Details>
      </DetailsContainer>
      <Button
        fullWidth
        sx={{ mt: '30px', textTransform: 'capitalize', fontSize: 16 }}
        variant='contained'
        onClick={saveChanges}
      >
        Save
      </Button>
    </Box>
  );
}
