import DashboardCard from '@Components/DashboardCard';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { selectUser } from '@Store/slices/auth/selectors';

import DashboardPayments from '@Components/dashboard-payments/DashboardPayments.Container';

const Dashboard: React.FC = () => {
  const user = useSelector(selectUser);

  return (
    <>
      <Box my={7.5} px={3.75} width='100%'>
        <Typography fontSize={34} fontWeight={700} variant='h2'>
          Welcome Back
        </Typography>
        <Typography fontSize={16} variant='subtitle1'>
          {user?.email}!
        </Typography>
      </Box>
      <Box display={'flex'} gap={3.75} px={3.75} width='100%'>
        <DashboardCard title='New Users' />
        <DashboardCard title='Parking Sessions - (for now duplicate of users)' />
      </Box>
      <Box my={4.5} px={3.75} width='100%'>
        <DashboardPayments />
      </Box>
    </>
  );
};

export default Dashboard;
