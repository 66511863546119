import { GridColDef } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { ExtendedGridColDef } from '@Types/ExtendedGridColDef';
import { formatTime } from '@Utils/formatTime';
import { IPayment, paymentMethodTypeValues } from '@Types/Payment';

export const paymentColumns: (GridColDef<IPayment> & ExtendedGridColDef)[] = [
  {
    field: 'clientId',
    headerName: 'User ID',
    hideable: false,
    sortable: false,
    flex: 0.2,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span className='table-cell-truncate'>{params.value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => `${params.row.clientId}""`,
  },
  {
    field: 'paymentMethod',
    headerName: 'Payment Method',
    hideable: false,
    sortable: false,
    flex: 0.2,
    renderCell: (params) => {
      const value = params.value;

      return <Tooltip title={value}>{value}</Tooltip>;
    },
    valueGetter: (params) =>
      (paymentMethodTypeValues as any)[params.row.paymentMethodType],
  },
  {
    field: 'amount',
    headerName: 'Amount',
    hideable: false,
    sortable: false,
    flex: 0.2,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span className='table-cell-truncate'>{params.value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => `£${params.row.amount?.toFixed(2)}`,
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    hideable: false,
    sortable: false,
    flex: 0.2,
    renderCell: (params) => {
      const dateStr = params.value;

      return (
        <Tooltip title={dateStr}>
          <span className='table-cell-truncate'>{dateStr}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => formatTime(params.row.createdAt),
  },
  {
    field: 'status',
    headerName: 'Status',
    hideable: false,
    sortable: false,
    flex: 0.2,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span
            className='table-cell-truncate'
            style={{ textTransform: 'capitalize' }}
          >
            {params.value}
          </span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.status.toLowerCase(),
  },
  {
    field: 'type',
    headerName: 'Type',
    hideable: false,
    sortable: false,
    flex: 0.2,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.type.toLowerCase(),
  },
  {
    field: 'timeZoneId',
    headerName: 'Time Zone',
    hideable: false,
    sortable: false,
    flex: 0.2,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.timeZoneId,
  },
];
