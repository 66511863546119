import { ITableRequestParams } from '@Types/Table';
import { apiSlice } from './apiSlice';
import { IGenericListModel } from '@Types/GenericListModel';
import { IPayment } from '@Types/Payment';

interface IRefundParams {
  transactionId: string;
  amount: number;
}

interface ILastPaymentsResponse {
  lastPayments: IPayment[];
  overallCountForMonth: number;
}

const paymentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    payments: builder.query<IGenericListModel<IPayment>, ITableRequestParams>({
      query: ({ sortFields, search, pageSize, currentPage, getAllPages }) => ({
        url: '/api/Payments/GetPaymentHistoryAll',
        method: 'POST',
        body: {
          sortFields,
          search,
          pageSize,
          currentPage,
          getAllPages,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: ['payments'] as any,
    }),
    lastPayments: builder.query<ILastPaymentsResponse, void>({
      query: () => ({
        url: '/api/Payments/GetPaymentHistoryStatistics',
      }),
      keepUnusedDataFor: 0,
    }),
    paymentsBySelectedId: builder.mutation<IPayment[], string[]>({
      query: (data) => ({
        url: '/api/Payments/GetPaymentHistoryBySelectedId',
        method: 'POST',
        body: data,
      }),
    }),
    refundPayment: builder.mutation<void, IRefundParams>({
      query: (data) => ({
        url: '/api/Payments/TransactionRefund',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['payments'] as any,
    }),
  }),
  overrideExisting: false,
});

export const {
  useRefundPaymentMutation,
  usePaymentsQuery,
  useLastPaymentsQuery,
  useLazyPaymentsQuery,
  usePaymentsBySelectedIdMutation,
} = paymentsApi;
