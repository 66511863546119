import { Routes, Route } from 'react-router-dom';

import DashboardLayout from '@Components/layouts/dashboard-layout';
import AuthLayout from '@Components/layouts/auth-layout';

import Dashboard from 'pages/dashboard';

import Users from 'pages/users';

import { Login } from 'pages/login';
import { ForgotPassword } from 'pages/forgot-password';
import { RecoverPassword } from 'pages/password-recovery';
import { TypePasscode } from 'pages/type-passcode';

import { Management } from 'pages/management';
import { Notifications } from 'pages/notifications';
import { SpaceTarrifMap } from 'pages/space-tarrif-map';

import { Reporting } from 'pages/reporting';
import { UserReport } from 'pages/user-report';

import {
  DASHBOARD_PAGE,
  USERS_PAGE,
  LOGIN_PAGE,
  FORGOT_PASSWORD_PAGE,
  RECOVER_PASSWORD_PAGE,
  TYPE_PASSCODE_PAGE,
  MANAGEMENT_PAGE,
  NOTIFICATIONS_PAGE,
  SPACE_TARRIF_MAP_PAGE,
  REPORTING_PAGE,
  USER_REPORT_PAGE,
  CUSTOMER_REPORT_PAGE,
  VEHICLE_REPORT_PAGE,
  SPACE_TARRIF_REPORT_PAGE,
  PAYMENT_HISTORY_PAGE,
  SESSION_PAGE,
  POLICIES_PAGE,
  FAQ_PAGE,
  TERMS_AND_CONDITIONS_PAGE,
  PRIVACY_POLICIES_PAGE,
  PARKING_GUIDE_PAGE,
  SPACE_TARRIF_MAP_NEW_CUSTOMER_PAGE,
  SPACE_TARRIF_MAP_EDIT_CUSTOMER_PAGE,
  NOTIFICATIONS_HISTORY_PAGE,
} from './constants';
import { CustomerReport } from 'pages/customer-report';
import { VehicleReport } from 'pages/vehicle-report';
import { SpaceTarrifReport } from 'pages/space-tarrif-report';
import { PaymentHistory } from 'pages/payment-history';
import { Sessions } from 'pages/sessions';
import { Policies } from 'pages/policies';
import { FAQ } from 'pages/FAQ';
import { TermsAndConditions } from 'pages/terms-and-conditions';
import { PrivacyPolicy } from 'pages/privacy-policy';
import { ParkingGuide } from 'pages/parking-guide';
import { SpaceTarrifMapNewCustomer } from 'pages/space-tarrif-map-new-customer/SpaceTarrifMapNewCustomer';
import NotificationsHistory from 'pages/notifications-history/NotificationsHistory.Container';

function Routing() {
  return (
    <Routes>
      <Route element={<AuthLayout />} path={LOGIN_PAGE}>
        <Route index element={<Login />} />
        <Route element={<ForgotPassword />} path={FORGOT_PASSWORD_PAGE} />
        <Route element={<RecoverPassword />} path={RECOVER_PASSWORD_PAGE} />
        <Route element={<TypePasscode />} path={TYPE_PASSCODE_PAGE} />
      </Route>
      <Route element={<DashboardLayout />} path={DASHBOARD_PAGE}>
        <Route index element={<Dashboard />} />
        <Route element={<Users />} path={USERS_PAGE} />

        <Route element={<Management />} path={MANAGEMENT_PAGE} />
        <Route element={<Notifications />} path={NOTIFICATIONS_PAGE} />
        <Route element={<SpaceTarrifMap />} path={SPACE_TARRIF_MAP_PAGE} />
        <Route
          element={<SpaceTarrifMapNewCustomer />}
          path={SPACE_TARRIF_MAP_NEW_CUSTOMER_PAGE}
        />
        <Route
          element={<SpaceTarrifMapNewCustomer />}
          path={SPACE_TARRIF_MAP_EDIT_CUSTOMER_PAGE}
        />

        <Route element={<Reporting />} path={REPORTING_PAGE} />
        <Route element={<UserReport />} path={USER_REPORT_PAGE} />
        <Route element={<CustomerReport />} path={CUSTOMER_REPORT_PAGE} />
        <Route element={<VehicleReport />} path={VEHICLE_REPORT_PAGE} />
        <Route
          element={<SpaceTarrifReport />}
          path={SPACE_TARRIF_REPORT_PAGE}
        />
        <Route element={<PaymentHistory />} path={PAYMENT_HISTORY_PAGE} />
        <Route
          element={<NotificationsHistory />}
          path={NOTIFICATIONS_HISTORY_PAGE}
        />
        <Route element={<Sessions />} path={SESSION_PAGE} />

        <Route element={<Policies />} path={POLICIES_PAGE} />
        <Route element={<FAQ />} path={FAQ_PAGE} />
        <Route
          element={<TermsAndConditions />}
          path={TERMS_AND_CONDITIONS_PAGE}
        />
        <Route element={<PrivacyPolicy />} path={PRIVACY_POLICIES_PAGE} />
        <Route element={<ParkingGuide />} path={PARKING_GUIDE_PAGE} />
      </Route>
    </Routes>
  );
}

export default Routing;
