export enum NotificationType {
  Unknown = 0,
  RequestAccess = 1,
  GrantAccess = 2,
  AcceptRequest = 3,
  RemoveRequest = 4,
  NotifyOnStart = 5,
  NotifyOnEnd = 6,
  NotifyOnMarketing = 7,
}

export interface INotificationHistory {
  id: string;
  sentAt: Date | string;
  sentFrom: string;
  sentTo: string;
  deviceId: string | null;
  notificationType: NotificationType;
}

export const notificationHistoryTypeValues: Record<NotificationType, string> = {
  [NotificationType.Unknown]: 'Unknown',
  [NotificationType.RequestAccess]: 'Request Access',
  [NotificationType.GrantAccess]: 'Grant Access',
  [NotificationType.AcceptRequest]: 'Accept Request',
  [NotificationType.RemoveRequest]: 'Remove Request',
  [NotificationType.NotifyOnStart]: 'Notify On Start',
  [NotificationType.NotifyOnEnd]: 'Notify On End',
  [NotificationType.NotifyOnMarketing]: 'Notify On marketing',
};
