import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

export default styled(Tab)(({ theme }) => ({
  color: theme.palette.primary.main,
  minWidth: 'auto',
  border: 0,
  backgroundColor: '#fff',
  padding: '10px 10px',
  borderRadius: '10px',
  fontSize: '16px',
  height: 32,
  minHeight: 32,
  textTransform: 'capitalize',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));
