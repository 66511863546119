import React from 'react';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled(Typography)`
  font-size: 21px;
  font-weight: 700;
`;

const TOS = styled(Box)`
  margin-top: auto;
`;

const TOSText = styled(Typography)`
  font-size: 16px;
  font-weight: 400;

  color: #b8b8b8;
  text-align: center;

  & > .blue {
    cursor: pointer;
    color: #59acf3;
  }
`;

interface IProps {
  title: string;
  withTOS?: boolean;
  children: React.ReactNode;
}

const LoginBoxWrapper: React.FC<IProps> = ({
  title,
  withTOS = true,
  children,
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      {children}
      {withTOS ? (
        <TOS>
          <TOSText>
            This site is protected by reCAPTCHA and the Google{' '}
            <span className='blue'>Privacy Policy</span> and{' '}
            <span className='blue'>Terms of Service</span> apply.
          </TOSText>
        </TOS>
      ) : null}
    </Container>
  );
};

export default LoginBoxWrapper;
