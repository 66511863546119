import { Appbar } from '@Components/app-bar';
import { Button, Box, styled } from '@mui/material';
import useTableRequestParams from 'hooks/useTableRequestParams';
import Table from '@Components/table';
import useActionColumn from '@Components/table/useActionColumn';
import { faqColumns } from './columns';
import AddNewIcon from '../../assets/icons/add-new-user-icon.svg';
import useModal from 'hooks/useModal';
import { CreateOrEditQuestionForm } from './create-or-edit-question';
import { Modal } from '@Components/modal';
import { useGetFAQQuery } from '@Store/api/policiesSlice';
import { useCallback, useEffect } from 'react';
import { IFAQ } from '@Types/FAQ';
import { handleFetchError } from '@Utils/handleError';

const Container = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const AddNewQuestionButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: '18px 20px',
  borderRadius: 30,
  color: '#FFF',
  img: {
    marginRight: 10,
  },
  textTransform: 'capitalize',
  '&:hover': { backgroundColor: theme.palette.secondary.main },
}));

export function FAQ() {
  const tableParams = useTableRequestParams();
  const { data, error: tableDataError } = useGetFAQQuery(tableParams);

  const {
    isOpen: isFAQModalOpen,
    item: FAQItem,
    openModal: openFAQModal,
    closeModal: closeFAQModal,
  } = useModal<IFAQ>();

  const {
    isOpen: isFAQDetailsOpen,
    item: FAQDetailsItem,
    openModal: openFAQDetailsModal,
    closeModal: closeFAQDetailsModal,
  } = useModal<IFAQ>();

  const handleOpenEditFAQ = useCallback(
    (FAQ: IFAQ) => {
      openFAQModal(FAQ);
    },
    [openFAQModal]
  );

  const handleOpenFAQDetails = useCallback(
    (FAQ: IFAQ) => {
      openFAQDetailsModal(FAQ);
    },
    [openFAQDetailsModal]
  );

  const actionColumn = useActionColumn({
    handleEdit: handleOpenEditFAQ,
    handleDetails: handleOpenFAQDetails,
  });

  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <Container>
      <Appbar searchable title={'FAQ'}>
        <AddNewQuestionButton onClick={() => openFAQModal()}>
          <img alt='Add New' src={AddNewIcon} />
          Add New
        </AddNewQuestionButton>
      </Appbar>
      <Table
        columns={[...faqColumns, actionColumn]}
        paginated={false}
        rows={data?.items || []}
        totalCount={data?.items?.length || 0}
      />

      {isFAQModalOpen ? (
        <Modal
          handleClose={closeFAQModal}
          label={FAQItem ? 'Edit question' : 'Add new question'}
          open={isFAQModalOpen}
        >
          <CreateOrEditQuestionForm FAQ={FAQItem} handleClose={closeFAQModal} />
        </Modal>
      ) : null}

      {isFAQDetailsOpen ? (
        <Modal
          handleClose={closeFAQDetailsModal}
          label={'Question details'}
          open={isFAQDetailsOpen}
        >
          <CreateOrEditQuestionForm
            readOnly
            FAQ={FAQDetailsItem}
            handleClose={closeFAQDetailsModal}
          />
        </Modal>
      ) : null}
    </Container>
  );
}
