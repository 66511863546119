export const exportData = (csv: string) => {
  const blob = new Blob([`\uFEFF${csv}`], { type: 'text/csv' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'IPS-Parking.csv';

  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};
