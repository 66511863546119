export enum PaymentMethodType {
  Unknown = 0,
  CC = 1,
  GP = 2, // Google pay
  AP = 3, // Apple pay
}

export type PaymentType =
  | 'INITIATED'
  | 'PREAUTHORIZED'
  | 'CAPTURED'
  | 'REVERSED'
  | 'DECLINED'
  | 'FUNDED'
  | 'REJECTED';

export enum ParkingSessionType {
  // Parking session started on the app and has no end until user interaction
  AppStartedNoEnd = 0,

  // Parking session started with both start and end times
  StartedWithTimes = 1,

  // Extended parking session with both start and end times
  ExtendedWithTimes = 2,

  // Extended parking session of a physical meter with no set end time
  ExtendedNoEndTime = 3,
}

export interface IPayment {
  id: string;
  clientId: string;
  subAreaId: string;
  paymentMethodType: PaymentMethodType;
  timestamp: Date;
  createdAt: Date;
  registrationNumber: string;
  amount: number;
  subAreaName: string;
  status: PaymentType;
  transactionId: string;
  type: 'REFUND' | 'SALE';
  timeZoneId: string;
}

export const parkingSessionTypeValues: Record<ParkingSessionType, string> = {
  [ParkingSessionType.AppStartedNoEnd]: 'App started no end',
  [ParkingSessionType.StartedWithTimes]: 'Started with times',
  [ParkingSessionType.ExtendedWithTimes]: 'Extended with times',
  [ParkingSessionType.ExtendedNoEndTime]: 'Extended no end time',
};

export const paymentMethodTypeValues: Record<PaymentMethodType, string> = {
  [PaymentMethodType.Unknown]: 'Unknown',
  [PaymentMethodType.CC]: 'Credit card',
  [PaymentMethodType.GP]: 'Google Pay',
  [PaymentMethodType.AP]: 'Apple Pay',
};
