import Box from '@mui/material/Box';

interface Iprops {
  children?: React.ReactNode;
  value: number;
  index: number;
}

const CustomTabPanel: React.FC<Iprops> = ({ value, index, children }) => {
  return (
    <Box
      aria-labelledby={`dashboard-statistics-${index}`}
      hidden={value !== index}
      role='tabpanel'
    >
      {value === index && (
        <Box alignItems='center' display='flex' sx={{ px: 3, pt: 3 }}>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default CustomTabPanel;
