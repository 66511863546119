import { Box, Button, styled, Typography } from '@mui/material';
import { IPolygonFormData } from 'pages/space-tarrif-map-new-customer/CustomerForm.validation';

const DetailsContainer = styled(Box)`
  margin-top: 30px;
  display: flex;
`;

const Details = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const DetailsTitle = styled(Typography)`
  color: #181818;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;

const DetailsValue = styled(Typography)`
  color: #181818;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const StyledTypography = styled(Typography)`
  color: #807f83;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;

const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  padding: '18px 20px',
  borderRadius: 20,
  color: '#FFF',
  marginTop: '60px',

  '&:hover': { backgroundColor: theme.palette.error.main },
}));

interface IProps {
  customer: IPolygonFormData;
  onDelete: () => void;
}

export function DeleteCustomerContainer({ customer, onDelete }: IProps) {
  return (
    <Box>
      <StyledTypography>
        You are going to delete customer permanently, are you sure about it?
      </StyledTypography>
      <DetailsContainer>
        <Details>
          <DetailsTitle>Name</DetailsTitle>
          <DetailsValue>{customer.name}</DetailsValue>
        </Details>
        <Details sx={{ ml: '60px' }}>
          <DetailsTitle>VAT Number</DetailsTitle>
          <DetailsValue>{customer.vatNumber}</DetailsValue>
        </Details>
        <Details sx={{ ml: '60px' }}>
          <DetailsTitle>Reminder Fee</DetailsTitle>
          <DetailsValue>{customer.defaultReminderFee}</DetailsValue>
        </Details>
        <Details sx={{ ml: '60px' }}>
          <DetailsTitle>Merchant ID</DetailsTitle>
          <DetailsValue>{customer.merchantId}</DetailsValue>
        </Details>
      </DetailsContainer>
      <DeleteButton fullWidth onClick={onDelete}>
        Delete
      </DeleteButton>
    </Box>
  );
}
