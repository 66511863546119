import { useCallback, useEffect } from 'react';

import { Box, styled } from '@mui/material';
import { AppBarWithApiRef } from '@Components/app-bar';
import { paymentColumns } from './columns';
import Table from '@Components/table';
import useTableRequestParams from 'hooks/useTableRequestParams';
import useActionColumn from '@Components/table/useActionColumn';
import { Modal } from '@Components/modal';
import useModal from 'hooks/useModal';
import { RefundPayment } from './RefundPayment';
import { useReactToPrint } from 'react-to-print';
import Papa from 'papaparse';
import {
  useLazyPaymentsQuery,
  usePaymentsBySelectedIdMutation,
  usePaymentsQuery,
  useRefundPaymentMutation,
} from '@Store/api/paymentsSlice';
import { exportData } from '@Utils/export-csv';
import { IPayment } from '@Types/Payment';
import { handleFetchError } from '@Utils/handleError';
import { toast } from 'react-toastify';

const Container = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export function PaymentHistory() {
  const tableParams = useTableRequestParams();

  const { data: payments, error: tableDataError } =
    usePaymentsQuery(tableParams);

  const [fetchSelectedPayments] = usePaymentsBySelectedIdMutation();

  const [getAllPayments] = useLazyPaymentsQuery();

  const [refundPayment] = useRefundPaymentMutation();

  const {
    isOpen: isRefundModalOpen,
    item: refundItem,
    openModal: openRefundModal,
    closeModal: closeRefundModal,
  } = useModal<IPayment>();

  const handleRefund = async () => {
    try {
      const _response = await refundPayment({
        transactionId: refundItem!.transactionId,
        amount: refundItem.amount,
      }).unwrap();
      closeRefundModal();
      toast.success('Payment was successfully reversed');
    } catch (err: any) {
      const message =
        err?.data?.message || err?.message || 'Something went wrong...';
      toast.error(message);
    }
  };

  const handleOpenRefundPayment = useCallback(
    (payment: IPayment) => {
      openRefundModal(payment);
    },
    [openRefundModal]
  );

  const actionColumn = useActionColumn({
    handleRefund: handleOpenRefundPayment,
    shouldDisableRefund: (row) => row.status !== 'CAPTURED',
  });

  const handlePrint = useReactToPrint({
    content: () => document.getElementById('payment-container'),
  });

  const handleExport = async (selectedIds?: string[]) => {
    const dataToExport = [] as any[];

    if (selectedIds) {
      const paymentsToExport = await fetchSelectedPayments(
        selectedIds
      ).unwrap();

      paymentsToExport.forEach((data) => {
        dataToExport.push([
          ...paymentColumns.map((column) =>
            column.valueGetter!({ row: data } as any)
          ),
        ]);
      });
    } else {
      const paymentsToExport = await getAllPayments({
        getAllPages: true,
      } as any).unwrap();

      paymentsToExport?.items?.forEach((data) => {
        dataToExport.push([
          ...paymentColumns.map((column) =>
            column.valueGetter!({ row: data } as any)
          ),
        ]);
      });
    }

    const csv = Papa.unparse({
      fields: paymentColumns.map((column) => column.headerName!),
      data: dataToExport,
    });

    exportData(csv);
  };
  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <Container id='payment-container'>
      <Table
        checkboxSelection
        printable
        searchable
        columns={[...paymentColumns, actionColumn]}
        handleExport={handleExport}
        handlePrint={handlePrint}
        paginated={payments?.items.length ? payments.items.length > 0 : false}
        rows={payments?.items || []}
        toolbar={AppBarWithApiRef}
        toolbarTitle='Payment History'
        totalCount={payments?.count}
      />
      {isRefundModalOpen ? (
        <Modal
          handleClose={closeRefundModal}
          label={'Refund'}
          open={isRefundModalOpen}
        >
          <RefundPayment payment={refundItem} onRefund={handleRefund} />
        </Modal>
      ) : null}
    </Container>
  );
}
