import { useEffect, useState } from 'react';
import ElevatedPaper from '@Components/ElevatedPaper';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import { Link } from 'react-router-dom';
import CustomTabPanel from '@Components/TabPanelComponent';
import CustomTabComponent from '@Components/CustomTabComponent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useDashboardUsersQuery } from '@Store/api/usersSlice';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { handleFetchError } from '@Utils/handleError';
//TODO: add sessions
interface Iprops {
  title: string;
}

const DataTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 700,
  fontSize: 70,
  lineHeight: '70px',
}));

const TitleTypography = styled(Typography)(() => ({
  fontSize: 21,
  lineHeight: '21px',
  fontWeight: 500,
}));

const DashboardCard: React.FC<Iprops> = ({ title }) => {
  const [value, setValue] = useState<number>(0);
  const { data: userData, error: tableDataError } = useDashboardUsersQuery();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <Box width={'50%'}>
      <ElevatedPaper>
        <TitleTypography>{title}</TitleTypography>
        <Tabs
          aria-label='users statistics tabs'
          sx={{
            '.MuiTabs-flexContainer': { gap: 1.25 },
            borderBottom: '1px solid',
            borderColor: 'rgba(24,24,24,0.05)',
            mt: 4,
          }}
          TabIndicatorProps={{
            sx: { display: 'none' },
          }}
          value={value}
          onChange={handleChange}
        >
          <CustomTabComponent
            aria-checked={value === 0}
            aria-label='users statistics tab - today'
            label='Today'
            value={0}
          />
          <CustomTabComponent
            aria-checked={value === 1}
            aria-label='users statistics tab - this week'
            label='This week'
            value={1}
          />
          <CustomTabComponent
            aria-checked={value === 2}
            aria-label='users statistics tab - this month'
            label='This month'
            value={2}
          />
          <CustomTabComponent
            aria-checked={value === 3}
            aria-label='users statistics tab - this year'
            label='This year'
            value={3}
          />
        </Tabs>
        {userData
          ? Object.keys(userData).map((key, index) => (
              <CustomTabPanel index={index} key={title + key} value={value}>
                <DataTypography>{userData[key]}+</DataTypography>
                <Button
                  component={Link}
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    ml: 'auto',
                    bgcolor: '#ffffff',
                    color: 'primary.main',
                    borderRadius: 30,
                    '&:hover': {
                      color: '#ffffff',
                    },
                    textTransform: 'capitalize',
                  }}
                  to={'/users'}
                  variant='contained'
                >
                  <Typography fontWeight={500} variant='body1'>
                    View More
                  </Typography>
                </Button>
              </CustomTabPanel>
            ))
          : null}
      </ElevatedPaper>
    </Box>
  );
};

export default DashboardCard;
