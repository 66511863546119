import { GridColDef } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { formatTime } from '@Utils/formatTime';
import { ExtendedGridColDef } from '@Types/ExtendedGridColDef';
import {
  IVehicleReport,
  vehicleReportStatusValues,
} from '@Types/VehicleReport';
import CopyColumn from '@Components/table/CopyColumn';

export const vehicleReportColumns: (GridColDef<IVehicleReport> &
  ExtendedGridColDef)[] = [
  {
    field: 'id',
    headerName: 'ID',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      return <CopyColumn value={params.value} />;
    },
    valueGetter: (params) => `${params.row.id}`,
  },
  {
    field: 'username',
    headerName: 'Username',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.username,
  },
  {
    field: 'registrationNumber',
    headerName: 'Reg. Number',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.registrationNumber,
  },
  {
    field: 'artEndDate',
    headerName: 'Art End Date',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return <Tooltip title={value}>{value}</Tooltip>;
    },
    valueGetter: (params) =>
      params.row.artEndDate === null ? '' : formatTime(params.row.artEndDate),
  },
  {
    field: 'co2Emissions',
    headerName: 'CO2e',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.co2Emissions,
  },
  {
    field: 'fuelType',
    headerName: 'Fuel Type',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.fuelType,
  },
  {
    field: 'status',
    headerName: 'Status',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },

    valueGetter: (params) => vehicleReportStatusValues[params.row.status],
  },
];
