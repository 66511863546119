/* eslint-disable react/jsx-props-no-spreading */
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';

import { Typography, styled } from '@mui/material';
import Button from '@mui/material/Button';

import LoginBoxWrapper from '@Components/layouts/login-box-wrapper';
import FormInput from 'UI/Form/FormInput';

import { FORGOT_PASSWORD_PAGE, TYPE_PASSCODE_PAGE } from '@Routes/constants';

import resolver, { ILogin } from './Login.validation';

import {
  useLoginMutation,
  useLazyGetProfileQuery,
} from '@Store/api/loginSlice';
import { login } from '@Store/slices/auth';
import { useState } from 'react';
import Loading from '@Components/loading/Loading';

const FormHelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  marginTop: 30,
  cursor: 'pointer',
}));

export function Login() {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [loginMutation] = useLoginMutation();
  const [getProfile] = useLazyGetProfileQuery();

  const navigate = useNavigate();

  const methods = useForm<ILogin>({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: resolver,
  });
  const { handleSubmit } = methods;

  const handleFormSubmit = async (data: ILogin): Promise<void> => {
    if (executeRecaptcha) {
      try {
        setIsLoading(true);

        const token = await executeRecaptcha();
        const resp = await loginMutation({
          ...data,
          recaptchaToken: token,
        }).unwrap();

        localStorage.setItem('accessToken', resp.accessToken);
        localStorage.setItem('refreshToken', resp.refreshToken);

        const params = jwt_decode(resp.accessToken) as { id: string };
        const user = await getProfile(params.id).unwrap();

        dispatch(login(user));
      } catch (err: any) {
        const message =
          err?.data?.message || err?.message || 'Something went wrong...';

        if (err.status === 202) {
          toast.success('OTP code sent successfully');

          navigate(TYPE_PASSCODE_PAGE, {
            state: {
              username: data.username,
            },
          });
        } else {
          toast.error(message);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFogotPasswordClicked = () => {
    navigate(FORGOT_PASSWORD_PAGE);
  };

  return (
    <LoginBoxWrapper title='Login'>
      {isLoading ? <Loading /> : null}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div style={{ marginTop: 30 }}>
            <FormInput label='Login' name='username' />
          </div>
          <div style={{ marginTop: 30 }}>
            <FormInput label='Password' name='password' type='password' />
          </div>
          <Button
            fullWidth
            sx={{ mt: '30px' }}
            type='submit'
            variant='contained'
          >
            Log In
          </Button>
        </form>
        <FormHelperText onClick={handleFogotPasswordClicked}>
          Forgot your <b>Password?</b>
        </FormHelperText>
      </FormProvider>
    </LoginBoxWrapper>
  );
}
