/* eslint-disable react/jsx-props-no-spreading */
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';

import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import { Box, styled } from '@mui/material';

import LoginBoxWrapper from '@Components/layouts/login-box-wrapper';
import FormInput from 'UI/Form/FormInput';

import resolver, { ITypePasscode } from './TypePasscode.validation';

import {
  useLazyGetProfileQuery,
  useVerifyMutation,
} from '@Store/api/loginSlice';

import jwt_decode from 'jwt-decode';
import { login } from '@Store/slices/auth';
import { RECOVER_PASSWORD_PAGE } from '@Routes/constants';
import { toast } from 'react-toastify';

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export function TypePasscode() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const [getProfile] = useLazyGetProfileQuery();

  const [verify] = useVerifyMutation();

  const methods = useForm<ITypePasscode>({
    defaultValues: {
      code: '',
    },
    resolver,
  });
  const { handleSubmit } = methods;

  const handleFormSubmit: SubmitHandler<ITypePasscode> = async (
    data
  ): Promise<void> => {
    try {
      const { username, navigateToTheRecoverPassword } = location.state;
      const code = data.code;

      const resp = await verify({ recipient: username, code }).unwrap();

      localStorage.setItem('accessToken', resp.accessToken);
      localStorage.setItem('refreshToken', resp.refreshToken);

      if (navigateToTheRecoverPassword) {
        navigate(RECOVER_PASSWORD_PAGE, {
          state: { username },
        });
      } else {
        const params = jwt_decode(resp.accessToken) as { id: string };
        const user = await getProfile(params.id).unwrap();

        dispatch(login(user));
      }
    } catch (err: any) {
      const message =
        err?.data?.message || err?.message || 'Something went wrong...';
      toast.error(message);
    }
  };

  return (
    <LoginBoxWrapper title='Log In' withTOS={false}>
      <FormProvider {...methods}>
        <FormContainer
          component={'form'}
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <div style={{ marginTop: 30 }}>
            <FormInput label='Type passcode' name='code' type='text' />
          </div>
          <Button
            fullWidth
            sx={{ mt: 'auto' }}
            type='submit'
            variant='contained'
          >
            Confirm
          </Button>
        </FormContainer>
      </FormProvider>
    </LoginBoxWrapper>
  );
}
