import { GridColDef } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';

import { ExtendedGridColDef } from '@Types/ExtendedGridColDef';
import CopyColumn from '@Components/table/CopyColumn';
import { ICustomerSelectionReport } from '@Types/customerType';

export const customerSelectionReportColumns: (GridColDef<ICustomerSelectionReport> &
  ExtendedGridColDef)[] = [
  {
    field: 'id',
    headerName: 'ID',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      return <CopyColumn value={params.value} />;
    },
    valueGetter: (params) => params.row.id,
  },
  {
    field: 'subareaname',
    headerName: 'Subarea Name',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.subareaName,
  },
  {
    field: 'subAreaId',
    headerName: 'Subarea ID',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      return <CopyColumn value={params.value} />;
    },
    valueGetter: (params) => params.row.subAreaId,
  },
  {
    field: 'configId',
    headerName: 'Config ID',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      return <CopyColumn value={params.value} />;
    },
    valueGetter: (params) => params.row.configId,
  },
  {
    field: 'defaultTime',
    headerName: 'Default Time (minutes)',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.defaultTime,
  },
  {
    field: 'convenienceFee',
    headerName: 'Convenience Fee',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.convenienceFee,
  },
  {
    field: 'hasVat',
    headerName: 'Has VAT',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => (params.row.hasVat ? 'Yes' : 'No'),
  },
  {
    field: 'customerName',
    headerName: 'Customer Name',
    hideable: false,
    flex: 0.1,
    renderCell: (params) => {
      const value = params.value;

      return (
        <Tooltip title={value}>
          <span className='table-cell-truncate'>{value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.customerName,
  },
];
