import { GridColDef } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { ExtendedGridColDef } from '@Types/ExtendedGridColDef';
import { INotification } from '@Types/Notifications';
import CopyColumn from '@Components/table/CopyColumn';

export const notificationsColumns: (GridColDef<INotification> &
  ExtendedGridColDef)[] = [
  {
    field: 'deviceId',
    headerName: 'Device ID',
    hideable: false,
    flex: 0.2,
    renderCell: (params) => {
      return params.value === null ? (
        'N/A'
      ) : (
        <CopyColumn value={params.value} />
      );
    },
    valueGetter: (params) =>
      params.row.deviceId === null ? null : `${params.row.deviceId}`,
  },
  {
    field: 'userId',
    headerName: 'User ID',
    hideable: false,
    flex: 0.2,
    renderCell: (params) => {
      return <CopyColumn value={params.value} />;
    },
  },
  {
    field: 'deviceToken',
    headerName: 'Token',
    hideable: false,
    flex: 0.15,
    renderCell: (params) => {
      return <CopyColumn value={params.value} />;
    },
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone number',
    hideable: false,
    flex: 0.15,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span className='table-cell-truncate'>{params.value}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    hideable: false,
    flex: 0.15,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span className='table-cell-truncate'>{params.value}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'isActive',
    headerName: 'Status',
    hideable: false,
    flex: 0.05,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span className='table-cell-truncate'>{params.value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => (params.row.isActive ? 'Active' : 'Inactive'),
  },
];
