import { ITableRequestParams } from '@Types/Table';
import { apiSlice } from './apiSlice';
import { INotification } from '@Types/Notifications';
import { IGenericListModel } from '@Types/GenericListModel';

const notificationsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<
      IGenericListModel<INotification>,
      ITableRequestParams
    >({
      query: ({ sortFields, search, pageSize, currentPage, getAllPages }) => ({
        url: '/api/NotificationManagement/GetAllDevicesNotificationSettings',
        method: 'POST',
        body: {
          sortFields,
          search,
          pageSize,
          currentPage,
          getAllPages,
        },
      }),
      keepUnusedDataFor: 0,
      providesTags: ['notifications'] as any,
    }),
    updateNotification: builder.mutation<void, INotification>({
      query: (data) => ({
        url: '/api/NotificationManagement/UpdateDeviceNotificationSettings',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['notifications'] as any,
    }),
  }),
  overrideExisting: false,
});

export const { useGetNotificationsQuery, useUpdateNotificationMutation } =
  notificationsApi;
