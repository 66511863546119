import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export interface ICreateOrEditUser {
  phoneNumber?: string;
  email?: string;
  isActive: boolean;
  roleIds: Array<string>;
}

const schema = yup.object<ICreateOrEditUser>().shape({
  phoneNumber: yup.string(),
  email: yup.string(),
  isActive: yup.boolean().required(),
  roleIds: yup
    .array()
    .of(yup.string().required('At least one role must be selected')) // Each element must be a string and not empty
    .min(1, 'At least one role must be selected') // Ensure there is at least one element in the array
    .required('At least one role must be selected'), // The array itself is required
});

export default yupResolver(schema);
