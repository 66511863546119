import { useCallback, useEffect } from 'react';

import ElevatedPaper from '@Components/ElevatedPaper';
import { Modal } from '@Components/modal';
import Table from '@Components/table';
import useActionColumn from '@Components/table/useActionColumn';

import { Button, styled, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import useModal from 'hooks/useModal';

import { RefundPayment } from 'pages/payment-history/RefundPayment';

import { paymentColumns } from './paymentColumns';

import {
  useLastPaymentsQuery,
  useRefundPaymentMutation,
} from '@Store/api/paymentsSlice';
import { Link } from 'react-router-dom';

import './styles.css';
import { IPayment } from '@Types/Payment';
import { handleFetchError } from '@Utils/handleError';
import { toast } from 'react-toastify';

const TitleTypography = styled(Typography)(() => ({
  fontSize: 21,
  lineHeight: '21px',
  fontWeight: 500,
}));

function DashboardPayments() {
  const { data: payments, error: tableDataError } = useLastPaymentsQuery();

  const [refundPayment] = useRefundPaymentMutation();

  const {
    isOpen: isRefundModalOpen,
    item: refundItem,
    openModal: openRefundModal,
    closeModal: closeRefundModal,
  } = useModal<IPayment>();

  const handleRefund = async () => {
    try {
      const _response = await refundPayment({
        transactionId: refundItem!.transactionId,
        amount: refundItem.amount,
      }).unwrap();
      closeRefundModal();
      toast.success('Payment was successfully reversed');
    } catch (err: any) {
      const message =
        err?.data?.message || err?.message || 'Something went wrong...';
      toast.error(message);
    }
  };

  const handleOpenRefundPayment = useCallback(
    (payment: IPayment) => {
      openRefundModal(payment);
    },
    [openRefundModal]
  );

  const actionColumn = useActionColumn({
    handleRefund: handleOpenRefundPayment,
    shouldDisableRefund: (row) => row.status !== 'CAPTURED',
  });

  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <>
      <ElevatedPaper>
        <TitleTypography>Payment History</TitleTypography>
        <Table
          className='payments-table'
          columns={[...paymentColumns, actionColumn]}
          footerComponent={() => {
            return (
              <>
                <Typography>{`${
                  payments?.overallCountForMonth || 0
                } payment in this month`}</Typography>
                <Button
                  component={Link}
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    ml: 'auto',
                    bgcolor: '#ffffff',
                    color: 'primary.main',
                    borderRadius: 30,
                    '&:hover': {
                      color: '#ffffff',
                    },
                    textTransform: 'capitalize',
                  }}
                  to={'/payment-history'}
                  variant='contained'
                >
                  <Typography fontWeight={500} variant='body1'>
                    View More
                  </Typography>
                </Button>
              </>
            );
          }}
          rows={payments?.lastPayments ? payments.lastPayments : []}
          totalCount={payments?.lastPayments ? payments.lastPayments.length : 0}
        />
      </ElevatedPaper>

      {isRefundModalOpen ? (
        <Modal
          handleClose={closeRefundModal}
          label={'Refund'}
          open={isRefundModalOpen}
        >
          <RefundPayment payment={refundItem} onRefund={handleRefund} />
        </Modal>
      ) : null}
    </>
  );
}

export default DashboardPayments;
