import { AppBarWithApiRef } from '@Components/app-bar';
import Table from '@Components/table';
import { exportData } from '@Utils/export-csv';
import { Box, styled } from '@mui/material';
import Papa from 'papaparse';
import { useReactToPrint } from 'react-to-print';
import { sessionsColumns } from './columns';
import {
  useLazySessionsQuery,
  useSessionsBySelectedIdMutation,
  useSessionsQuery,
} from '@Store/api/sessionsSlice';
import useTableRequestParams from 'hooks/useTableRequestParams';
import { useEffect } from 'react';
import { handleFetchError } from '@Utils/handleError';

const Container = styled(Box)`
  padding: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export function Sessions() {
  const tableParams = useTableRequestParams();

  const { data: sessions, error: tableDataError } =
    useSessionsQuery(tableParams);

  const [fetchSelectedSessions] = useSessionsBySelectedIdMutation();
  const [getAllSessions] = useLazySessionsQuery();

  const handlePrint = useReactToPrint({
    content: () => document.getElementById('sessions-container'),
  });

  const handleExport = async (selectedIds?: string[]) => {
    const dataToExport = [] as any[];

    if (selectedIds) {
      const paymentsToExport = await fetchSelectedSessions(
        selectedIds
      ).unwrap();

      paymentsToExport.forEach((data) => {
        dataToExport.push([
          ...sessionsColumns.map((column) =>
            column.valueGetter!({ row: data } as any)
          ),
        ]);
      });
    } else {
      const paymentsToExport = await getAllSessions({
        getAllPages: true,
      } as any).unwrap();

      paymentsToExport?.items?.forEach((data) => {
        dataToExport.push([
          ...sessionsColumns.map((column) =>
            column.valueGetter!({ row: data } as any)
          ),
        ]);
      });
    }

    const csv = Papa.unparse({
      fields: sessionsColumns.map((column) => column.headerName!),
      data: dataToExport,
    });

    exportData(csv);
  };

  useEffect(() => {
    handleFetchError(tableDataError);
  }, [tableDataError]);

  return (
    <Container id='sessions-container'>
      <Table
        checkboxSelection
        printable
        searchable
        columns={[...sessionsColumns]}
        handleExport={handleExport}
        handlePrint={handlePrint}
        paginated={sessions?.items.length ? sessions.items.length > 0 : false}
        rows={sessions?.items || []}
        toolbar={AppBarWithApiRef}
        toolbarTitle='Sessions'
        totalCount={100}
      />
    </Container>
  );
}
